.support-hints .content {
    border: 1px dashed var(--bs-gray-400);
    padding: 0.5rem;
    border-radius: var(--bs-border-radius-lg);
}

.support-ticket-form textarea {
    border-radius: 10px;
    height: 100px;
    padding: 10px;
    width: 100%;
    font-size: var(--font-size-sm);
    color: var(--text-color);
    background-color: var(--input-background-color);
    border: 1px solid var(--input-background-color);
}

.support-ticket-list .date {
    color: var(--bs-gray-600);
    font-size: 0.8rem;
}

.support-ticket-list .ticket-responses h3 {
    font-size: 16px;
    font-weight: 700;
}

.support-ticket-list .ticket-responses .attach {
    text-decoration: none;
    color: var(--bs-dark);
    padding-top: 2px;
}

.support-ticket-list .ticket-responses .ticket-response {
}

.support-ticket-list .message {
    display: -webkit-box;
    max-width: 100%;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    line-height: 17px;
}



.sss-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 14px;
    border-radius: 10px;
    border: 1px solid #e8e6d0;
    margin-bottom: 8px;
}

.sss-item a {
    width: 100%;
}
.sss-item, .sss-item a {
    display: flex;
    align-items: center;
    justify-content: space-between;
}


.accordion-list {
    transition: background-color .2s;
    cursor: pointer;
    border-radius: 8px;
}
