.register-widget{
    display: flex;
    flex-direction: column;
    /* padding: 20px; */
    border-radius: var(--bs-border-radius-xl);
    background: #FFFFFF;
    width: 472px;
    max-width: calc(100% - 20px);
    z-index: 12;
    position: fixed;
    top: 50vh;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%);
}

.btn-close {
    position: absolute;
    right: 20px;
    top: 20px;
}

.register-form{
    background: #FFFFFF;
    border-radius: var(--bs-border-radius-xl);
    padding: 1.25rem;
    z-index: 2;
    position: relative;

}

.register-widget-background {
    right: 0;
    width: 50%;
    z-index: -1;
    position: absolute;
    height: 100%;
    border-top-right-radius: var(--bs-border-radius-xl);
    border-bottom-right-radius: var(--bs-border-radius-xl);
}

.register-background-image {
    width: 100%;
    height: 100%;
    right: 0;
    z-index: -1;
    position: absolute;
    border-top-right-radius: var(--bs-border-radius-xl);
    border-bottom-right-radius: var(--bs-border-radius-xl);
}

.register-background-logo {
    right: 50%;
    transform: translate(50%);
    top: 100px;
    z-index: -1;
    position: absolute;
    width: 180px;
}

.register-background-desc {
    right: 50%;
    transform: translate(50%);
    position: absolute;
    bottom: 10%;
    font-size: 36px;
    color: #FFFFFF;
    font-style: italic;
    width: 80%;
    padding: 20px;
}

.react-datepicker__input-container input {
    background-color: #FFFFFF;
    border-color: var(--bs-gray-500)
}

.checkbox-text {
    font-size:12px;
}

.password-tip {
    background: #5E68DD;
}

.outline-none {
    outline: none;
}

.register-mobile{

}
.register-mobile a{
    color: #bfc1e8;
}
.register-mobile form .checkbox label{
    color: #FFFFFF;
    width: calc(100% - 27px);
}
.register-form form .checkbox label{

    width: calc(100% - 27px);
}
.register-mobile-header{
    text-align: center;
    padding-top: 4rem;
    padding-bottom: 4rem;
}

.password-eye {
    margin-top: -30px;
    position: relative;
    right: 10px;
    float: right;
}



.register-select {
    padding: 14px;
    box-shadow: 0 1px 2px 0 rgba(16, 24, 40, .05);
    border-radius: var(--border-radius);
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    font-size: var(--font-size-sm);
    color: #929191;
    background-color: var(--input-background-color);
    /*background-image: url(/_next/static/media/chevron-down.26a06cca.svg);*/
    background-repeat: no-repeat;
    background-position-x: 92%;
    background-position-y: 12px;
    border: none;
}

.checkbox-input-wrapper {
    display: grid;
    grid-template-columns: 12px 1fr;
    grid-gap: 16px;
    margin-bottom: 4px;
}

.checkbox-input {
    display: none;
}

.checkbox-input+label {
    display: inline-block;
    position: relative;
    cursor: pointer;
}

.checkbox-input+label:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 20px;
    height: 20px;
    border-radius: 6px;
    border: 1px solid #d7d7d7;
    transition: all .5s ease;
}

.checkbox-input:checked+label:before {
    background-color: var(--primary-color);
    border: 1px solid var(--primary-color);
}

.checkbox-input:checked+label:after {
    content: url(../../assets/icons/check.svg);
    position: absolute;
    left: 4px;
    top: 3px;
    font-size: 14px;
    color: #fff;
}
