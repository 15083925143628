:root {
    --primary-color: #004742;
    --primary-dark-color: #003834;
    --primary-light-color: hsla(0, 0%, 100%, .2);
    --secondary-color: #ffe700;
    --text-color: #353535;
    --disabled-text-color: #b1ae8f;
    --error-color: #e04c4c;
    --success-color: #129b3f;
    --input-background-color: #f0f1ec;
    --form-text-disabled-color: #929191;
    --border-color: #d4d6ce;
    --background-color: #fff;
    --disabled-color: #eeecdb;
    --background-light-color: #f7f8f5;
    --border-green-color: #336c68;
    --border-light-color: #e2e2e2;
    --font-size-xxxs: 10px;
    --font-size-xxs: 11px;
    --font-size-xs: 12px;
    --font-size-sm: 14px;
    --font-size-md: 16px;
    --font-size-lg: 18px;
    --font-size-xl: 24px;
    --font-size-xxl: 32px;
    --font-weight-light: 300;
    --font-weight-normal: 400;
    --font-weight-regular: 500;
    --font-weight-medium: 600;
    --font-weight-bold: 700;
    --font-weight-bolder: 800;
    --font-weight-ultra-bold: 900;
    --line-height-xxxs: 12px;
    --line-height-xxs: 18px;
    --line-height-xs: 22px;
    --line-height-sm: 24px;
    --line-height-md: 26px;
    --line-height-lg: 28px;
    --line-height-xl: 30px;
    --gutter: 12px;
    --border-radius: 8px;
    --rt-color-white: #fff;
    --rt-color-dark: #222;
    --rt-color-success: #8dc572;
    --rt-color-error: #be6464;
    --rt-color-warning: #f0ad4e;
    --rt-color-info: #337ab7;
    --rt-opacity: 0.9;
    --rt-transition-show-delay: 0.15s;
    --rt-transition-closing-delay: 0.15s

}

body {

    -webkit-font-smoothing: antialiased;
    font-family: "Inter", sans-serif;

}

* {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
}

*::-webkit-scrollbar {
    display: none;
}

#root, .App {
    position: relative;
    max-width: 100%;
    padding: 0;
    margin: 0;
    height: 100vh;
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
    overflow-y: scroll !important;
    line-height: normal;
}

/*.container {*/
/*    margin: 0 auto;*/
/*    max-width: 1064px;*/
/*    padding: 0 12px*/
/*}*/

/*@media screen and (max-width: 1280px) {*/
/*    .container {*/
/*        max-width: 1064px*/
/*    }*/
/*}*/

/*@media screen and (max-width: 1024px) {*/
/*    .container {*/
/*        max-width: 984px*/
/*    }*/
/*}*/

/*@media screen and (max-width: 768px) {*/
/*    .container {*/
/*        max-width: 744px*/
/*    }*/
/*}*/

/*@media screen and (max-width: 575px) {*/
/*    .container {*/
/*        max-width: 564px*/
/*    }*/
/*}*/

.hide-stepper::-webkit-outer-spin-button, .hide-stepper::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.hide-stepper[type=number] {
    -moz-appearance: textfield;
}

img {
    outline: none;
    border: none;
    display: inline;
    vertical-align: middle;
}

.bg-green {
    background-color: var(--primary-color);

}

p {
    color: var(--text-color);
    font-weight: var(--font-weight-medium);
    margin-bottom: 0;
}

a {
    text-decoration: none;
    color: #FFFFFF;
    font-weight: var(--font-weight-medium);
    font-size: var(--font-size-md);
}

.cursor-pointer{
    cursor: pointer;
}
.row {
    margin-left: 0;
    margin-right: 0;

}

.text-primary {
    color: var(--primary-color) !important;
    font-weight: var(--font-weight-medium);
    font-size: var(--font-size-md);
}

.text-secondary {
    color: var(--secondary-color) !important;
}

.text-gray {
    color: #63676f;
}

.text-lg {
    font-size: var(--font-size-lg) !important;
}

.text-small {
    color: var(--text-color);
    font-weight: var(--font-weight-normal);
    font-size: var(--font-size-sm);
}

.text-xsmall {
    color: var(--text-color);
    font-weight: var(--font-weight-normal);
    font-size: var(--font-size-xs);
}

.text-xxsmall {
    color: var(--text-color);
    font-weight: var(--font-weight-normal);
    font-size: var(--font-size-xxs);
}

.text-xxxsmall {
    color: var(--text-color);
    font-weight: var(--font-weight-normal);
    font-size: var(--font-size-xxxs);
}

.lh-xxxsmall {
    line-height: var(--line-height-xxxs);
}

.text-bolder {
    font-weight: var(--font-weight-ultra-bold);
}

.fw-medium {
    font-weight: var(--font-weight-medium) !important;
}

.text-bold {
    font-weight: var(--font-weight-bold);
}

.course {
    border-radius: 30px;

    padding: 2px 6px;
    margin-right: 4px;
}

.divider {
    background: var(--border-color);
    height: 1px;
    margin: 8px 0;
}

.run-tabs {
    display: flex;
    gap: 8px;
    background-color: var(--primary-color);
    padding: 0 12px;
    overflow: scroll;
    margin: 0;
    -ms-overflow-style: none;
    scrollbar-width: none;
}

@media screen and (max-width: 768px) {
    .run-tabs.possible-widget {
        width: calc(100vw - 24px);
    }
}

.run-tab-item {
    padding: 5.5px 11px;
    border-radius: 8px 8px 0 0;
    background: var(--primary-light-color);
    transition: all .3s ease;
    width: 100%;
    cursor: pointer;
}

.run-tab-item.active {
    background: var(--secondary-color) !important;
}

.run-tab-item.active > p {
    color: var(--primary-color) !important;
}

.possibles-table .runs .line {
    align-items: center;
    border: 1px solid #0000;
    display: flex;
    font-weight: 600;
    justify-content: space-between;
    margin-top: 2px;
    position: relative;
    width: 100%;
    height: 50px;
}

.possibles-table .runs .line:nth-child(odd) {
    background: #f7f8f5;
}

.possibles-table .runs .line:nth-child(even) {
    background: #ffffff;
}

.hippodrome-container {
    background-color: var(--primary-color);
    /*overflow: scroll;*/
    /*max-width: 1064px;*/
    margin: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
    /*padding-top: 24px;*/
}

.hippodrome-wrapper {
    position: -webkit-sticky;
    position: sticky;
    top: 60px;
    left: 0;
    right: 0;
    z-index: 3;
    background-color: var(--primary-color);
    padding-top: 10px;
}


.backdrop-blur {
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    background: #00000060;
    display: none;
    height: 100%;
    left: 0;
    min-height: 100vh;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 11;
}

.modal {
    position: fixed;
    z-index: 1050;
    display: none;
    max-width: 460px;
    max-height: calc(100vh - 10px);
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    background: #FFFFFF;
    overflow: hidden;
    outline: 0;
    border-radius: var(--bs-border-radius-xl);
}

.modal .footer {
    background: var(--bs-white);
    padding-bottom: 24px !important;
    padding-left: 24px !important;
    padding-right: 24px !important;
}

.modal .body {
    padding: 10px;
    overflow: scroll;
    background: var(--bs-white);
}

.modal .header {
    padding: 10px;
    background: #FFFFFF;
    font-weight: 700;
    position: relative;
}

.modal .header img {
    display: block;
    width: 75px;
    background: #FFFFFF;
    border-radius: 50%;
    padding: 10px;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    z-index: 1;
}

.modal.success .header {
    background: unset;
    color: var(--bs-success);
    border-bottom: 0 solid var(--bs-success);
    text-align: center;
    padding: 0;
}

.modal.danger .header {
    background: unset;
    color: var(--bs-danger);
    border-bottom: 0 solid var(--bs-danger);
    text-align: center;
    padding: 0;
}

.modal.warning .header {
    background: unset;
    color: var(--bs-warning);
    border-bottom: 0 solid var(--bs-warning);
    text-align: center;
    padding: 0;
}

.modal.info .header {
    background: unset;
    color: var(--bs-primary);
    border-bottom: 0 solid var(--bs-primary);
    text-align: center;
    padding: 0;
}


.modal.info .header:after, .modal.danger .header:after, .modal.warning .header:after, .modal.success .header:after {
    content: '';
    background: #FFF;
    width: 100%;
    height: 45px;
    position: absolute;
    left: 0;
    top: 30px;
    z-index: 0;
    border-radius: var(--bs-border-radius-xl) var(--bs-border-radius-xl) 0 0;
}


.modal.success .body {
    color: var(--bs-success);
}

.modal.danger .body {
    color: var(--bs-danger);
}

.modal.info .body {
    color: var(--bs-blue);
}

.modal.warning .body {
    color: var(--bs-warning);
}

.modal.success .footer button.btn-success {
    border: 2px solid var(--bs-success);
    background: var(--bs-success-bg-subtle);
    color: var(--bs-success);
    font-weight: bold;
}

.modal.success .footer button.btn-secondary {
    border: 2px solid var(--bs-secondary);
    background: var(--bs-secondary-bg-subtle);
    color: var(--bs-secondary);
    font-weight: bold;
}

.modal.success .footer button.btn-info {
    border: 2px solid var(--bs-info);
    background: var(--bs-info-bg-subtle);
    color: var(--bs-info);
    font-weight: bold;
}

.modal.warning .footer button.btn-success {
    border: 2px solid var(--bs-success);
    background: var(--bs-success-bg-subtle);
    color: var(--bs-success);
    font-weight: bold;
}

.modal.warning .footer button.btn-secondary {
    border: 2px solid var(--bs-secondary);
    background: var(--bs-secondary-bg-subtle);
    color: var(--bs-secondary);
    font-weight: bold;
}

.modal.warning .footer button.btn-info {
    border: 2px solid var(--bs-info);
    background: var(--bs-info-bg-subtle);
    color: var(--bs-info);
    font-weight: bold;
}

.modal.warning .footer button.btn-warning {
    border: 2px solid var(--bs-warning);
    background: var(--bs-warning-bg-subtle);
    color: var(--bs-warning);
    font-weight: bold;
}

.modal.warning .footer button.btn-default {
    border: 2px solid var(--bs-warning);
    background: var(--bs-white);
    color: var(--bs-warning);
    font-weight: bold;
}

.modal.danger .footer button.btn-danger {
    border: 2px solid var(--bs-danger);
    background: var(--bs-danger-bg-subtle) !important;
    color: var(--bs-danger);
    font-weight: bold;
}

.modal.danger .footer button.btn-primary {
    border: 2px solid var(--bs-primary);
    background: var(--bs-primary-bg-subtle) !important;
    color: var(--bs-primary);
    font-weight: bold;
}

.modal.info .footer button.btn-info {
    border: 2px solid var(--bs-info);
    background: var(--bs-info-bg-subtle);
    color: var(--bs-info);
    font-weight: bold;
}

.modal.info .footer button.btn-primary {
    border: 2px solid var(--bs-primary);
    background: var(--bs-primary-bg-subtle);
    color: var(--bs-primary);
    font-weight: bold;
}

.modal.info .footer button.btn-danger {
    border: 2px solid var(--bs-danger);
    background: var(--bs-danger-bg-subtle);
    color: var(--bs-danger);
    font-weight: bold;
}

.modal.info .footer button.btn-secondary {
    border: 2px solid var(--bs-success);
    background: #FFFFFF;
    color: var(--bs-success);
    font-weight: bold;
}

.modal.info .footer button.btn-success {
    border: 2px solid var(--bs-success);
    background: var(--bs-success);
    color: #FFFFFF;
    font-weight: bold;
}

.modal .footer button:not(.w-100) {
    margin-left: 0.5rem;
}

.modal .footer button.w-100 {
    margin-top: 0.5rem;
}

.modal .body > h2.title {
    font-size: 19px;
    font-weight: 700;
    text-align: center;
}

.modal.show {
    display: block;
}

.modal.show2 {
    display: none;
    opacity: 0;
}

.modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1040;
    display: none;
    width: 100%;
    height: 100%;

    background-color: #00000060;
    backdrop-filter: blur(10px);

}

.modal-backdrop.show {
    display: block;
}

.modal-close img {
    width: 30px;
    height: 30px;
    cursor: pointer;
}

.modal-close {
    position: absolute;
    right: 10px;
    top: 10px;
    border-radius: 50%;
    z-index: 999;
}

img.modal-close {
    width: 22px !important;
    height: 22px !important;
    position: absolute;
    right: 10px;
    top: 10px;
}


.ReactModal__Content.default .modal-body {
    max-height: calc(100vh - 180px);
    overflow: scroll;
    padding-bottom: 40px;
    margin-left: -1.5rem;
    margin-right: -1.5rem;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    background: #ffffff;
}

.modal-body {
    padding: 0 24px 0 24px;
    max-height: calc(-160px + 100vh);
    overflow: scroll;
    width: 100%;
    background: #ffffff;
}

.ReactModal__Content.default {
    top: 50%;
    left: 50%;
    right: auto;
    bottom: auto;
    margin-right: -50%;
    max-width: calc(100% - 80px);
    width: 370px;
    padding: 1.5rem;
    max-height: calc(100vh - 100px);
    transform: translate(-50%, -50%);
    background: var(--bs-white);
    position: absolute;
    overflow: auto;
    outline: 0;
    border-radius: var(--bs-border-radius);
}

.ReactModal__Content.default h2 {
    font-size: 18px;
    font-weight: 700;
    height: 30px;
    margin: 0;
}

form .form-row {
    display: flex;
    margin-left: -0.35rem;
    margin-right: -0.35rem;
    margin-bottom: 1rem;
}

form .form-row > div {
    padding-left: 0.35rem;
    padding-right: 0.35rem;
}

form input:focus {
    border: 1px solid var(--primary-color);
}

form input {
    border-radius: 10px;
    height: 32px;
    padding: 21px 10px;
    width: 100%;
    font-size: var(--font-size-sm);
    color: var(--text-color);
    background-color: var(--input-background-color);
    border: 1px solid var(--input-background-color);
}
form input[type=file]{
    height: unset;
    padding:10px;
}
form input[type=checkbox] {
    margin-right: 10px;
    margin-top: 0;
    width: 17px;
    height: 17px;
    border: 2px solid #1E3D98;
    accent-color: #1E3D98;
}

form .checkbox label {
    float: left;
    display: contents;
}

form .invalid-feedback {
    font-size: 13px;
    color: var(--bs-danger);
    margin-bottom: 5px;
}


.button {
    font-family: Arial, sans-serif;
    border-radius: var(--border-radius);
    transition: all .3s;
    text-align: center;
    display: inline-block;
    cursor: pointer;
    font-size: var(--font-size-sm);
    height: 44px;
    position: relative;
    outline: 0;
    border: 0;
    line-height: normal;
}

.button:disabled{
    cursor: not-allowed;
}

.button.primary {
    background-color: var(--primary-color);
    color: var(--background-color)
}

.button.danger {
    background-color: var(--error-color);
    color: var(--background-color)
}

.button.primary.outlined {
    background-color: transparent;
    border: 1px solid var(--primary-color);
    color: var(--primary-color)
}

.button.primary.outlined:hover {
    background-color: var(--primary-color);
    color: var(--primary-color)
}

.button.primary.filled:hover {
    background-color: #00423d
}

.button.primary.linked {
    color: var(--primary-color)
}

.button.primary.linked, .button.primary.transparent {
    background-color: transparent
}

.button.secondary {
    background-color: var(--secondary-color);
    color: var(--text-color)
}

.button.secondary.outlined {
    background-color: transparent;
    border: 1px solid var(--secondary-color);
    color: var(--secondary-color)
}

.button.secondary.outlined:hover {
    background-color: var(--secondary-color);
    color: var(--text-color)
}

.button.secondary.filled:hover {
    background-color: #ccb900
}

.button.secondary.linked {
    background-color: none;
    color: var(--secondary-color)
}

.button.tertiary {
    background-color: var(--background-color);
    color: var(--text-color)
}

.button.tertiary.outlined {
    background-color: transparent;
    border: 1px solid var(--border-color);
    color: var(--text-color)
}

.button.tertiary.filled:hover, .button.tertiary.outlined:hover {
    background-color: #e6e6e6
}

.button.tertiary.linked {
    background-color: none;
    color: #2c3e50
}

.button.disabled {
    background-color: var(--disabled-color);
    cursor: not-allowed
}

.button.disabled p {
    color: var(--disabled-text-color) !important
}

.button.disabled:hover {
    background-color: var(--disabled-color) !important
}

.button .icon {
    position: absolute;
    right: 16px;
    top: 13px
}

.register_select {
    padding: 14px;
    box-shadow: 0 1px 2px 0 rgba(16, 24, 40, .05);
    border-radius: var(--border-radius);
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    font-size: var(--font-size-sm);
    color: #929191;
    background-color: var(--input-background-color);
    background-repeat: no-repeat;
    background-position-x: 92%;
    background-position-y: 12px;
    border: none;
}


.betting-select {
    padding: 12px;
    box-shadow: 0 1px 2px 0 rgba(16, 24, 40, .05);
    border-radius: var(--border-radius);
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    font-size: var(--font-size-sm);
    color: var(--primary-color);
    font-weight: 700;
    background-repeat: no-repeat;
    background-position-x: 92%;
    background-position-y: 12px;
    border: 1px solid var(--border-color);
    min-width: 25%;
    background-color: #fff;
    cursor: pointer;
}

.btn-link {
    border: 1px solid var(--border-color);
    border-radius: var(--border-radius);
    min-height: 45px;
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    gap: 4px;
}


.betting-run-tabs {
    display: flex;
    background-color: var(--secondary-color);
    padding: 8px 8px 0 15px;
    border-radius: 12px 12px 0 0;
    gap: 10px;
    margin-top: 16px;
}

.weather-info {
    padding-bottom: 8px;
}

.run-betting-tabs {
    display: flex;
    gap: 8px;
    flex: 1 1;
}

.run-betting-item.active {
    background-color: #fff;
}

.run-betting-item {
    border-radius: 8px 8px 0 0;
    background: #fff8b0;
    gap: 2px;
    padding: 5px 0 5px 12px;
    width: 100%;
    position: relative;
    cursor: pointer;
    display: flex;
    align-items: center;
}

.run-betting-item > .run {
}

.run-betting-item > .badge {
    position: absolute;
    right: 10px;
    font-size: var(--font-size-xxs);
    padding: 5px 4px;
    border-radius: 6px;
    background-color: rgba(53, 53, 53, .102);
    color: var(--text-color);
    top: 25%;
}

.race-badge {
    width: fit-content;
    padding: 5px 6px;
    border-radius: 30px;
    font-size: 10px !important;
    background-color: var(--text-color);
    color: var(--background-color);
    font-weight: var(--font-weight-bold);
}

.betting-run-info {
    padding: 12px;
    border-left: 1px solid var(--secondary-color);
    border-right: 1px solid var(--secondary-color);
}

.betting-table tr {
    background-color: #fff;
}

.betting-table tr:first-child {
    border-bottom: 1px solid #e8e6d0;
}

.betting-table tr th {
    background-color: var(--primary-color);
    padding: 14px 7px;
    font-size: var(--font-size-xxxs);
    font-weight: var(--font-weight-bold);
    line-height: var(--line-height-xxxs);
    min-height: 35px;
    height: 100%;
    border-right: 1px solid var(--primary-light-color);
    border-bottom: 1px solid var(--primary-light-color);
    min-width: 0;
    color: #ffffff;
    vertical-align: middle;
}

.betting-table tr:nth-child(even) {
    background-color: #f7f8f5;
}

.select-all-horse {
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 2px;
    width: 30px;
    height: 26px;
}

.selected-horse {
    background-color: var(--primary-color) !important;
    color: #fff !important;
}

.selected-horse-all {
    background-color: var(--secondary-color) !important;
    color: #fff !important;
}

.selected-horse span {
    color: #fff !important;
}

.horse-added {
    background: #fffbd1 !important;
}

.horse-name-col {
    text-align: left;
    padding: 14px 12px;
}

.betting-table tr td:first-child {
    padding: 15px 0;
    min-width: 31px;
    background-color: var(--secondary-color);
    border-bottom: 1px solid #fff;
}

.betting-table tr td {
    overflow: hidden;
    text-overflow: ellipsis;
    border-right: 1px solid #e8e6d0;
    border-bottom: 1px solid #e8e6d0;
}


.betting-table tr td:not(:last-child) {
    padding: 3px 12px;
}


.betting-table tr td.last-six {
    max-width: 60px;
    min-width: 60px;
    width: 60px;
    padding: 21px 5px !important;
}

.betting-table tr td.ganyan {
    max-width: 50px;
    min-width: 50px;
    width: 50px;
    padding: 21px 10px !important;
}

.betting-table tr td.idm {
    max-width: 45px;
    min-width: 45px;
    width: 45px;
    padding: 21px 7px !important;
}

.betting-table tr td.horse-button {
    min-width: 46px;
    max-width: 46px;
    width: 46px;
    padding: 10px 8px !important;
}

.betting-table tr td.weight {
    max-width: 51px;
    min-width: 51px;
    width: 51px
}

.betting-table tr td.age, .betting-table tr td.weight {
    padding: 21px 6px !important;
    letter-spacing: -.3px
}

.betting-table tr td.age {
    max-width: 48px;
    min-width: 48px;
    width: 5%
}

.betting-table tr td.pedigri {
    padding: 9px 3px !important;
    max-width: 50px;
    min-width: 50px;
    width: 50px
}

.betting-table tr td.pedigri .more {
    border-radius: 20px;
    border: 1px solid #e8e6d0;
    background: #fff;
    padding: 4px 8px;
    margin-top: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 9px;
    width: 45px
}

.betting-table tr td.trainer {
    max-width: 50px;
    min-width: 50px;
    width: 5%;
    padding: 21px 6px !important;
}

.betting-table tr td.start {
    max-width: 38px;
    min-width: 38px;
    width: 5%;
    padding: 21px 13.5px !important;
}

.betting-table tr td.hp {
    max-width: 28px;
    min-width: 28px;
    width: 5%;
    padding: 21px 7.5px !important;
}

.betting-table tr td.kgs {
    max-width: 35px;
    min-width: 35px;
    width: 5%;
    padding: 3px 5px !important;
}


.betting-table tr td.eid {
    max-width: 74px;
    min-width: 74px;
    width: 7.5%;

}

.betting-table tr td.horse-name {
    max-width: 197px;
    min-width: 197px;
    width: 20%;
    cursor: pointer
}

.betting-table tr td.jockey {
    max-width: 156px;
    min-width: 156px;
    width: 156px;
}

.jockey-image-border {
    border: 1px solid #e8e6d0;
    border-radius: 100%;
    padding: 4px;
    margin-right: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: #fff;
}


.last-run-K {
    color: #967117;
    font-weight: bold;
}

.last-run-C {
    color: #008000;
    font-weight: bold;
}

li, ol, ul {
    list-style-type: none;
    margin: 0;
}


.page-title-wrapper {
    background-color: var(--primary-color);
}

.hipodrome-tab-container {
    background-color: var(--primary-color);
    overflow: scroll;
    max-width: 1064px;
    margin: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.tab-items-wrapper {
    display: flex;
    align-items: center;
    gap: 15px;
}

.tab-item {
    padding: 12px 0 8px 12px;
    background-color: var(--primary-light-color);
    border-radius: 10px 10px 0 0;
    min-width: 160px;
    cursor: pointer;
    position: relative;
    letter-spacing: -.3px;
}


.tab-item.active {
    background-color: var(--background-color);
    padding: 12px 0 8px 16px;
}

.tab-active-indicator {
    width: 8px;
    height: 16px;
    background-color: var(--secondary-color);
    position: absolute;
    top: 13px;
    left: 0;
}


@media screen and (max-width: 768px) {
    .tab-items-wrapper {
        gap: 8px;
        overflow: auto;
    }
}

.tooltip:hover > .tooltip-text {
    display: block;
}

.tooltip > .tooltip-text {
    display: none;
    position: absolute;
    width: 200px;
    background: #003834;
    border-radius: var(--bs-border-radius);
    padding: 12px;
    white-space: normal;
    margin-top: 5px;
    z-index: 1;
    color: #FFFFFF;
    font-size: 12px;
    margin-left: -15px;
}

.tooltip:hover > .tooltip-text::after {
    content: "";
    position: absolute;
    top: -14px;
    left: 7%;
    margin-left: 0;
    border-width: 7px;
    border-style: solid;
    border-color: transparent transparent #003834 transparent;
}

.result-tab {
    border-radius: 10px 10px 0 0;
}

.result-selected {
    background-color: #fff;
}

.date-picker-btn {
    cursor: pointer;
    position: relative;
}

.result-wrapper {
    padding-top: 22px;
    padding-bottom: 120px;
}

.page-result tr:first-child {
    border-bottom: 1px solid #e8e6d0;
}

.page-result tr:nth-child(odd) {
    background-color: #fff;
}

.page-result tr:nth-child(2n) {
    background-color: #f7f8f5;
}

.page-result tr th {
    background-color: var(--primary-color);
    padding: 11px 8px;
    min-height: 35px;
    height: 100%;
    border-right: 1px solid var(--primary-light-color);
    border-bottom: 1px solid var(--primary-light-color);
    min-width: 0;
}

.page-result tr td:not(:last-child) {
    padding: 3px 12px;
}

.page-result tr td {
    overflow: hidden;
    text-overflow: ellipsis;
    border-right: 1px solid #e8e6d0;
    border-bottom: 1px solid #e8e6d0;
}

.result-horse-name {
    width: 230px;
    max-width: 230px;
    min-width: 230px;
}

.page-result tr td.result-age, .page-result tr td.result-weight {
    letter-spacing: -.3px;
    padding: 21px 11px;
}

.result-age {
    width: 55px;
    max-width: 55px;
    min-width: 55px;
}


.page-result tr td.result-weight {
    width: 50px;
    max-width: 50px;
    min-width: 50px;
}

.result-jockey {
    width: 170px;
    max-width: 170px;
    min-width: 170px;
}

.result-start {
    width: 58px;
    max-width: 58px;
    min-width: 58px;
}

.result-degree {
    width: 78px;
    max-width: 78px;
    min-width: 78px;
    letter-spacing: -.3px;
}

.result-ganyan {
    width: 70px;
    max-width: 70px;
    min-width: 70px;
    letter-spacing: -.3px;
}

.result-diffrance {
    width: 75px;
    max-width: 75px;
    min-width: 75px;
}

.result-late-start {
    width: 74px;
    max-width: 74px;
    min-width: 74px;
    letter-spacing: -.3px;
}


.result-hp {
    width: 72px;
    max-width: 72px;
    min-width: 72px;
}

.result-agf {
    width: 80px;
    max-width: 80px;
    min-width: 80px;
}

.result-date-input {
    background-color: #004742 !important;
    border: 0 !important;
    margin-left: 10px;
    font-size: 20px !important;
    color: #FFFFFF !important;
    font-weight: var(--font-weight-bolder);
    outline: 0;
    caret-color: #004742 !important;
    cursor: pointer !important;
}

.result-info-wrapper {
    margin-top: 20px;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(336px, 1fr));
    grid-gap: 16px;
    gap: 16px;
}

.result-info-card-header {
    padding: 10px 12px;
    border-radius: 10px 10px 0 0;
    background: var(--primary-color);
    display: flex;
    align-items: center;
}

.result-prize-wrapper {
    max-height: 210px;
    height: 210px;
    overflow: auto;
    overflow-x: hidden;
    background-color: #f7f8f5;
    border-bottom-right-radius: 12px;
    border-bottom-left-radius: 12px;
}

.result-prize-table {
    width: 100%;
}

.result-prize-wrapper .result-prize-table tr:last-child {
    border-bottom-right-radius: 12px;
    border-bottom-left-radius: 12px;
}

.result-prize-wrapper .result-prize-table tr:nth-child(odd) {
    background-color: #fff;
}

.result-prize-wrapper .result-prize-table tr {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(42px, 1fr));
}

.result-prize-wrapper .result-prize-table tr th:not(:last-child) {
    border-right: 1px solid #ded35b;
}

.result-prize-wrapper .result-prize-table tr th {
    background-color: var(--secondary-color);
    padding: 9px 12px;
    border-bottom: 1px solid #ded35b;
    font-size: 12px;
    font-weight: 700;
    text-align: center;
}

.result-prize-wrapper .result-prize-table tr:nth-child(odd) {
    background-color: #fff;
}

.result-prize-wrapper .result-prize-table tr {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(42px, 1fr));
}


.result-prize-wrapper .result-prize-table tr td:first-child {
    text-align: left;
}

.result-prize-wrapper .result-prize-table tr td:not(:last-child) {
    border-right: 1px solid #e8e6d0;
}

.result-prize-wrapper .result-prize-table tr td {
    padding: 12px;
    border-bottom: 1px solid #e8e6d0;
    text-align: center;
}

.result-prize-wrapper .result-prize-table tr td:last-child {
    text-align: right;
}

.result-info-card-body {
    min-height: 210px;
}

.info-not-found {
    min-height: 210px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border-radius: 0 0 10px 10px;
    border: 1px solid #e8e6d0;
    background: #f7f8f5;
}

.stream-wrapper {
    background-color: var(--primary-color);
    padding: 20px 16px;

}

.stream-pills {
    display: flex;
    align-items: center;
}

.stream-pill {
    background: #336c68;
    padding: 9px 16px;
    border-radius: 8px 0 0 8px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 150px;
    height: 36px;
}

.stream-pill.active, .stream-pill.active {
    background: #fff;
}


.stream-pill.right {
    border-radius: 0 8px 8px 0 !important;
}

.stream-video-wrapper {
    width: 100%;
    background-color: var(--input-background-color);
    padding: 24.87px 18.66px 18.66px;
    border-radius: 12.44px;
}

.sticky-page-title {
    position: -webkit-sticky;
    position: sticky;
    top: 60px;
    left: 0;
    right: 0;
    z-index: 3;
    background-color: var(--primary-color);
    padding-top: 10px;
}

.possibles-run-wrapper {
    display: flex;
    gap: 8px;
    background-color: var(--primary-color);
    overflow: scroll;
    margin: 0 -1px;
    border-radius: 14px 14px 0 0;
    padding: 12px 12px 0;
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.table-wrapper {
    border-radius: 14px;
    border: 1px solid #e8e6d0;
    background: #f7f8f5;
    display: flex;
    flex-direction: column;
}

.possible-horse-name {
    display: flex;
    justify-content: space-between;
}

.program-bets-info {
    display: flex;
    align-items: center;
    background: var(--secondary-color);
    padding: 8px 12px;
    border-radius: 6px;
    margin-top: 12px;
    gap: 8px;
}

.editors-bg {
    position: fixed;
    left: 0;
    z-index: -1;
    bottom: 0;
    width: calc(50vw - 350px);
    background-color: #eee;
    height: calc(100vh + 100px);
}

.comments-wrapper {
    display: grid;
    grid-template-columns: 300px 1fr;
    grid-gap: 20px;
    gap: 20px;
    padding-top: 20px;
    padding-bottom: 20px;
    position: relative;
    z-index: 3;

}

.comments-title {
    padding: 8px 12px;
}

.editor-card {
    padding: 16.25px 12px;
    border-radius: 10px 0 0 10px;
    display: flex;
    align-items: center;
    gap: 10px;
    cursor: pointer;
}

.editor-card.selected {
    background-color: #fff !important;
    border-radius: 10px 0 0 10px !important;
}

.editor-card > div:nth-child(2) {
    flex-grow: 2;
}

.editor-img {
    object-fit: cover;
    border-radius: 10px;
}

.comment-count {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 11px;
    font-weight: 900;
    margin-left: .5rem;
    border-radius: 9999px;
    padding: .2rem;
    width: 20px;
    height: 20px;
    background-color: #004742;
    color: #fff;
}

.comment-wrapper {
    height: calc(100vh - 10px);
    overflow-y: auto;
}

.editor-info {
    margin-top: 10px;
    margin-bottom: 20px;
    width: 100%;
    gap: 10px;
    display: flex;
    padding: 0 8px;
    align-items: center;

}

.editor-table-wrapper {
    border-radius: 14px;
    border: 1px solid #c9e1df;
    background: #f7f8f5;
    display: flex;
    flex-direction: column;
    cursor: pointer;
}

.editor-table-header {
    background-color: var(--primary-color);
    padding: 8px 12px;
    border-radius: 14px 14px 0 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 -1px;
}

.editor-table-tab-wrapper {
    display: flex;
    gap: 10px;
    background-color: var(--primary-color);
    padding: 0 12px;
    overflow: scroll;
    margin: 0 -1px;
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.editor-hipodrome-item {
    padding: 12px 15px;
    border-radius: 8px 8px 0 0;
    background: var(--primary-light-color) !important;
    transition: all .3s ease;
    /*width: 20%;*/
    cursor: pointer;
}

.editor-hipodrome-item.selected {
    background: #fff !important;
}

.editor-comment-wrapper {
    display: grid;
    padding: 16px;
    grid-gap: 12px;
    gap: 12px;
    background: #fff;
    border: 1px solid #fff;
    border-bottom-right-radius: 14px;
    border-bottom-left-radius: 14px;
    max-height: 77vh;
    overflow-y: auto;
    cursor: auto;
}

.editor-comment {
    padding-bottom: 12px;
    border-bottom: 1px solid #e8e6d0;
    line-height: 18px;
}


.editor-comment:last-child {
    border-bottom: none;
    padding-bottom: 0;
}



.editord-mobile-header {
    font-size: var(--font-size-sm);
    padding: 20.25px;
    background-color: var(--primary-color);
    color: #fff;
    font-weight: 700;
}

.editord-mobile-container {
    display: grid;
    padding: 22px 12px;
    grid-gap: 8px;
    gap: 8px;
}
.editord-mobile-table-wrapper {
    border-radius: 14px;
    border: 1px solid #f0f1ec;
    background: #f7f8f5;
    display: flex;
    flex-direction: column;
    cursor: pointer;
}


.editord-mobile-list-header-closed {
    border-radius: 10px !important;
    background: #f0f1ec !important;
}

.editord-mobile-list-header {
    background-color: var(--primary-color);
    padding: 15px 12px;
    border-radius: 14px 14px 0 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 -1px;
    min-height: 50px;
}
@media screen and (max-width: 768px) {
    .editord-mobile-list-header {
        padding: 19px 12px;
    }
}
@media screen and (max-width: 768px) {
    .editor-table-tab-wrapper {
        width: calc(100vw - 24px);
    }
}
.comment-count.selected {
    background-color: #fff;
    color: var(--primary-color) !important;
}
.openedIcon {
    transition: all .2s ease;
    transform: rotate(180deg);
}


.hippodrome-gap {
    padding-bottom: 18px;
    padding-top: 9px;

}

.agf-tab-wrapper {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(42px, 1fr));
    grid-gap: 8px;
    gap: 8px;
    background-color: var(--primary-color);
    padding: 12px 12px 0;
    overflow: scroll;
    border-radius: 14px 14px 0 0;
}

@media screen and (max-width: 768px) {
    .agf-tab-wrapper {
        margin: 0 -12px;
        background-color: #fff;
    }
    .agf-tab-wrapper > .run-tabs {
        background-color: #fff;
    }
    .agf-tab-wrapper > .run-tabs > .run-tab-item {
        background: #f0f1ec;
    }
    .agf-tab-wrapper > .run-tabs > .run-tab-item p {
        color: var(--text-color) !important;
    }
}

.agf-page-table tr:nth-child(odd) {
    background-color: #f7f8f5;
}

.agf-page-table tr {
    position: relative;
    height: 50px;
}

.agf-page-table tr th {
    background-color: var(--secondary-color);
    padding: 10.5px 12px;
    border-right: 1px solid #ded35b;
    border-bottom: 1px solid #ded35b;
}

.agf-page-table tr th:first-child {
    border-bottom-color: #fff;
}

.agf-page-table tr:nth-child(2n) {
    background-color: #fff;
}

.agf-page-table tr td:nth-child(2) {
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 10.5px 12px !important;
}

.agf-table-sort {
    background-color: var(--secondary-color) !important;
    height: auto;
}

.agf-page-table tr td {
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 10.5px 12px;
    border-right: 1px solid #e8e6d0;
    border-bottom: 1px solid #e8e6d0;
}

.text-field-wrapper {
    position: relative;
    margin-bottom: 20px;
    width: 100%;
}

.text-filed-input {
    height: 32px;
    padding: 21px 10px;
    border-radius: 10px;
    width: 100%;
    font-size: var(--font-size-sm);
    color: var(--text-color);
    background-color: var(--input-background-color);
    border: 1px solid var(--input-background-color);
}

.text-filed-addon {
    position: absolute;
    right: 14px;
    top: 38px;
}


input.code-input:not(:last-child) {
    margin-right: calc(5px * 2);
}

.code-input-seperator {
    width: 12px;
    text-align: center;
    padding-top: 7px;
    margin-right: 10px;
}

.code-input {
    text-align: center;
    flex: 1 0 1em;
    border: 1px solid var(--bs-gray-600);
    width: 60px !important;
    height: 66px !important;
    border-radius: var(--border-radius);
    background-color: var(--input-background-color);
    font-size: 42px;
    font-weight: 400
}

.code-input::-webkit-outer-spin-button,
.code-input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
.code-input[type=number] {
    -moz-appearance: textfield;
}


.password-validator-item {
    background-color: var(--input-background-color);
    color: #929191;
    margin-right: 4px;
    padding: 4px;
    border-radius: 2px;
    margin-bottom: 14px;
    display: inline-block;
    font-size: 11px !important;
}


.not-found-wrapper {
    margin-top: 44px;
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
    margin-bottom: 100px;
    min-height: 365px
}

.not-found-status-wrapper {
    position: relative
}

.not-found-status-wrapper .not-found-status-image {
    width: 390px;
    height: 220px
}

.not-found-status-wrapper .not-found-status-code {
    position: absolute;
    font-size: 96px;
    letter-spacing: -.44px;
    bottom: -45px;
    left: 50%;
    transform: translateX(-50%);
    color: var(--primary-color)
}

.not-found-text {
    margin-top: 40px;
    margin-bottom: 44px;
    line-height: 14px
}

.not-found-back-home {
    width: 294px;
    display: flex;
    justify-content: center;
    align-items: center
}


.filter-mobile-buttons-wrapper {
    display: flex;
    align-items: center;
    gap: 8px;
    padding-top: 16px;
    padding-bottom: 6px;
    border-bottom: 1px solid hsla(75, 9%, 82%, .5);
    margin-left: 12px;
    margin-right: 12px;
}

.mobile-race-info-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    white-space: nowrap;
}

.mobile-race-info {
    display: flex;
    align-items: center;
    gap: 4px;
}

.mobile-route-button-wrapper {
    display: flex;
    gap: 8px;
    width: 100%;
}


.mobile-route-href {
    border: 1px solid var(--border-color);
    border-radius: var(--border-radius);
    min-height: 45px;
}

.mobile-route-href {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    gap: 4px;
}

.mobile-filter-wrapper {
    display: flex;
    gap: 8px;
    padding-top: 16px;
}


.betting-race-info-wrapper {
    padding: 12px;
    border-left: 1px solid var(--secondary-color);
    border-right: 1px solid var(--secondary-color);
}

.betting-race-info {
    background-color: transparent;
    border: none;
    overflow: hidden;
    text-align: center;
    min-width: 95px;
}

.betting-race-info-top-section {
    border: 1px solid #ccc;
    column-gap: 2px;
    background-color: var(--background-color);
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
}

.betting-race-info-item {
    padding: 0 4px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.betting-race-info-bottom-section {
    margin-top: -1px;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    background-color: #967117;
    border: 1px solid #967117;
}


@media screen and (max-width: 768px) {
    .mobile-route-href {
        width: 50%;
    }
}


@media screen and (max-width: 1024px) {
    .run-betting-item {
        min-width: 66px;
        padding: 8px 0 5px 9px;
        margin-top: 5px;
        margin-left: 3px;
    }

    .betting-run-tabs {
        gap: 8px;
        border-radius: 0;
        /*padding: 8px 8px 0 12px;*/
        overflow: scroll;
        -ms-overflow-style: none;
        scrollbar-width: none;
        padding: 0;
        border-bottom: 1px solid var(--secondary-color);
        margin-top: 5px;
    }
}

@media screen and (max-width: 1024px) {
    .mobile-filter-wrapper {
        padding-left: 12px;
        padding-right: 12px;
        padding-top: 6px;
    }
}


@media screen and (max-width: 1024px) {
    .betting-container {
        padding: 0;
    }
}

@media screen and (max-width: 768px) {
    .mobile-run {
        display: flex;
        flex-direction: column;
    }

    .mobile-run span {
        line-height: 14px;
    }
}


@media screen and (max-width: 768px) {
    .filter-mobile-buttons-wrapper {
        flex-wrap: wrap
    }
}


@media screen and (max-width: 575px) {
    .not-found-status-wrapper .not-found-status-image {
        width: 360px;
        height: 202px
    }
}


.betting-mobile table {
    width: 100%;
    table-layout: fixed;
}


.betting-mobile .mobile-horse-col {
    width: 100%;
    min-width: 165px;
}


.betting-mobile .mobile-jockey-col {
    width: 100%;
}

.betting-mobile .mobile-last-six-col {
    width: 113px;
}

.betting-mobile .mobile-detail-col {
    min-width: 33px;
    max-width: 33px;
    width: 33px;
}

@media screen and (max-width: 575px) {
    .betting-mobile .mobile-horse-col {
        width: 100%;
        min-width: 100%;
    }

    .betting-mobile .mobile-jockey-col {
        width: 113px;
    }

    .betting-mobile .mobile-last-six-col {
        width: 55px;
    }

    .mobile-betting-jockey-name {
        max-width: 100px;
    }
}

.betting-mobile tr {
    border-bottom: 1px solid #e8e6d0;
    background: linear-gradient(180deg, #fff, #f7f8f5);
}

.betting-mobile tr th:first-child {
    width: 20px;
}

.betting-mobile tr th {
    background-color: var(--primary-color);
}

.betting-mobile tr th:not(:first-child) {
    padding: 14px 12px;
}

.betting-mobile tr td:first-child {
    background-color: var(--secondary-color);
    width: 20px;
}

.betting-mobile tr td:not(:last-child) {
    border-right: 1px solid #e8e6d0;
}

.betting-mobile tr td:not(:first-child) {
    padding: 10px 8px;
}

.betting-mobile tr td {
    overflow: hidden;
    text-overflow: ellipsis;
}

.mobile-betting-horse-box {
    min-width: auto;
    max-width: 100%;
}

.mobile-betting-horse-no {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 4px;
}

.select-all-horse-mobile {
    background-color: var(--secondary-color) !important;
    color: var(--primary-color);
}

.mobile-betting-trainer-box {
    min-width: 113px;
    max-width: 113px;
    width: 113px;
}


.betting-mobile tr td.mobile-bet-is-open {
    background-color: var(--secondary-color);
}

.betting-mobile tr td:last-child {
    padding: 5px 7px;
}

.opened-mobile-table-wrapper {
    padding: 12px !important;
    border-top: 4px solid var(--secondary-color);
    background-color: #f0f1ec !important;
}

.mobile-betting-vertical-divider {
    border-right: 1px solid #dadad6;
    padding-right: 12px;
}

.mobile-opened-table-item:not(:last-child) {
    border-bottom: 1px solid #dadad6;
}

.mobile-opened-table-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: 36px;
    gap: 4px;
}

.mobile-opened-table-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
}


.betting-long-text-mobile {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    margin: auto 0;
}

@media screen and (max-width: 768px) {
    .betting-long-text-mobile {
        max-width: 100px;
    }
}

.betting-mobile-blue-button {
    background-color: var(--secondary-color);
    padding: 6px 10px;
    border-radius: 20px;
    display: flex;
    align-items: center;
    gap: 4px;
}

.mobile-betting-selected {
    background: var(--primary-color) !important;
}

@media screen and (max-width: 1024px) {
    .result-mobile-run-tabs {
        gap: 8px;
        border-radius: 0;
        padding: 8px 8px 0 12px;
        overflow: scroll;
        background-color: transparent;
        -ms-overflow-style: none;
        scrollbar-width: none;
        margin-top: 0;
    }

    .result-wrapper {
        padding-top: 5px;
        padding-bottom: 120px;
    }

    .run-result-item {
        min-width: 54px;
        padding: 8px 0 2px 12px;
        background: #f0f1ec;
    }

    .run-result-item.active {
        background-color: var(--secondary-color);
    }

    .betting-run-info {
        background-color: var(--secondary-color);
        padding: 6px 12px;

    }


}

.result-mobile-table tr, .result-mobile-table tr th {
    border-bottom: 1px solid #e8e6d0;
    background: linear-gradient(180deg, #fff, #f7f8f5);
}

.result-mobile-table tr th:first-child {
    width: 20px;
}

.result-mobile-table tr th {
    border-right: 1px solid #e8e6d0;
    border-top: 1px solid #e8e6d0;
}

.result-mobile-table tr th:not(:first-child) {
    padding: 11px 12px;
}

.result-mobile-table tr td:first-child {
    background-color: var(--secondary-color);
    width: 20px;
}

.result-mobile-table tr td:not(:last-child) {
    border-right: 1px solid #e8e6d0;
}


.result-mobile-table tr td {
    transition: all .2s ease;
    overflow: hidden;
    text-overflow: ellipsis;
}

.result-mobile-horse-box {
    min-width: auto;
    max-width: 100%;
}

.result-mobile-trainer-box {
    min-width: 113px;
    max-width: 113px;
    width: 113px;
}

.result-mobile-table tr td.result-is-open {
    background-color: var(--secondary-color);
}

@media screen and (max-width: 575px) {
    .result-horse-col {
        width: 100%;
        min-width: 100%;
    }

    .result-jockey-col {
        width: 113px;
    }

    .result-start-col {
        width: 62px;
    }

    .result-detail-col {
        min-width: 33px;
        max-width: 33px;
        width: 33px;
    }
}

.result-mobil-detail {
    padding: 12px !important;
    border-top: 4px solid var(--secondary-color);
    background-color: #f0f1ec !important;
}

.result-mobil-detail-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.result-mobil-detail-vertical-divider {
    border-right: 1px solid #dadad6;
    padding-right: 12px;
}

.result-mobil-detail-item:not(:last-child) {
    border-bottom: 1px solid #dadad6;
}

.result-mobil-detail-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: 36px;
    gap: 4px;
}

.result-mobil-detail-item:nth-child(2) {
    min-height: 46px;
}

.tipster-not-found {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    min-height: 400px;
    background-color: #fff !important;
    border: 1px solid #fff !important;
}



.ganyan-six {
    background-color: var(--primary-color);
    display: flex;
    flex: 1 1;
    align-items: center;
    justify-content: flex-end;
}

@media screen and (max-width: 768px) {
    .ganyan-six {
        padding: 16px 12px 0;
    }
}



.tjkIframe{
    font-size: 12px;
    padding: 0px;
}
.tjkIframe > .grid_10{
    width: 50%;
    float: left;
}
.tjkIframe > .grid_14{
    width: 50%;
    float: left;
}
.tjkIframe > .grid_14 .grid_8 .value{ font-weight: 600;  }
.tjkIframe > .grid_14 .grid_8 .value a{ font-size: 12px  }
.tjkIframe > .grid_14 .grid_8 .key{ width: 40%;
    position: relative;
    display: inline-block;
    margin-bottom: 3px;
    vertical-align: top;
    font-weight: bold;
    font-size: 12px;
}
.tjkIframe > .grid_14 .grid_8{
    width: 60%;
    float: left;
}
.tjkIframe > .grid_14 .grid_6 .value{ width: 45% !important; float: right; font-weight: 600;  }
.tjkIframe > .grid_14 .grid_6 .key{ width: 45% !important; display: inline-block; }

.tjkIframe > .grid_14 .grid_6{
    width: 40%;
    float: left;
    padding-right: 15px;
}
.tjkIframe #queryTable{
    width: 100%;
    border:0;
}

/*.tjkIframe #queryTable  thead th:nth-child(odd) {*/
/*    background: var(--color-bg-light);*/
/*}*/
.tjkIframe #queryTable  thead th{
    background: var(--primary-color);
    text-align: center;
    padding: 10px 3px;
}
.tjkIframe #queryTable  thead th a{
   font-size: 12px;
}
.tjkIframe #queryTable  tbody tr td:nth-child(even) {
    background: var(--color-bg-light);
}
.tjkIframe #queryTable  tbody tr td{
    border-bottom: 1px solid var(--primary-color);
}
.tjkIframe #queryTable  tbody tr td a{
    font-size: 12px;
}


.tjkIframe #queryTable.horseStats  tbody tr td:nth-child(15), .tjkIframe #queryTable.horseStats  thead tr th:nth-child(15){
    display: none;
}
.tjkIframe #queryTable.horseStats  tbody tr td:nth-child(16), .tjkIframe #queryTable.horseStats  thead tr th:nth-child(16){
    display: none;
}
.tjkIframe #queryTable.horseStats  tbody tr td:nth-child(13), .tjkIframe #queryTable.horseStats  thead tr th:nth-child(13){
    display: none;
}

.tjkIframe #queryTable.jockeyStats  tbody tr td:first-child, .tjkIframe #queryTable.jockeyStats  thead tr th:first-child{
    display: none;
}

.tjkIframe #queryTable.jockeyStats  tbody tr td a {
    color: var(--text-color);
}



.tjkIframe #queryTable.jockeyStats td{
    min-width: 35px;
    width: max-content;
    white-space: nowrap;
    padding: 4px;
}

.tjkIframe #queryTable  .hidable{
    display: none;
}
.tjkIframe #queryTable  tbody tr td{
    padding: 0px 3px;
    text-align: center;
    font-size: 12px;
}


.react-datepicker__triangle {
    display: none;
}

.react-datepicker__day.react-datepicker__day--keyboard-selected {
    border: none;
    border-radius: 7px;
    /*background-color: var(--dark);*/
    /*color: var(--white);*/
}

.react-datepicker__day.react-datepicker__day--keyboard-selected:hover {
    border: none;
    border-radius: 7px;
    /*background-color: var(--dark);*/
    /*color: var(--white);*/
}

.react-datepicker-popper .react-datepicker__navigation {
    padding-top: 12px;
    color: #000;
}

.react-datepicker {
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.189);
    border: none !important;
    font-family: "Inter" !important;
}

.react-datepicker__header {
    /*border-bottom: solid 5px var(--light) !important;*/
    background: white !important;
}

.react-datepicker__current-month {
    /*color: var(--dark) !important;*/
}

.react-datepicker__day.react-datepicker__day--today {
    border-radius: 7px;
    /*border: solid 2px var(--brand) !important;*/
    background-color: white !important;
    /*color: var(--dark) !important;*/
    width: 30px;
    height: 30px;
}

.react-datepicker__day.react-datepicker__day--selected {
    border: none;
    border-radius: 7px;
    /*background-color: black;*/
    /*color: white;*/
}

.react-datepicker__day--selected:hover,
.react-datepicker__day--in-selecting-range:hover,
.react-datepicker__day--in-range:hover,
.react-datepicker__month-text--selected:hover,
.react-datepicker__month-text--in-selecting-range:hover,
.react-datepicker__month-text--in-range:hover,
.react-datepicker__quarter-text--selected:hover,
.react-datepicker__quarter-text--in-selecting-range:hover,
.react-datepicker__quarter-text--in-range:hover,
.react-datepicker__year-text--selected:hover,
.react-datepicker__year-text--in-selecting-range:hover,
.react-datepicker__year-text--in-range:hover {
    border: none;
    border-radius: 7px !important;
    /*background-color: var(--brand) !important;*/
    /*color: var(--dark) !important;*/
}
