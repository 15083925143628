.slider-wrapper .slide .caption p {
    color: #FFF;
    font-size: 17px;
}

.slider-wrapper .slide .caption h3 {
    color: var(--bs-warning);
    font-weight: 700;
    font-size: 46px;
    margin-bottom: 0;
}

.slider-wrapper .slide .caption h2 {
    color: #FFF;
    font-weight: 400;
    font-size: 28px;
    margin-bottom: 0;
}

.slider-wrapper .slide .caption {
    position: absolute;
    left: 50vw;
    top: 115px;
    width: 500px;

}

.slider-wrapper .slide {
    position: relative;

}

.slider-wrapper .slide img {
    height: auto;
    width: 100%;
    object-fit: cover;
    border-radius: 12px;
}

.slider-wrapper {
    background: var(--primary-color);
}

.slider-wrapper .swiper-button-next, .slider-wrapper .swiper-button-prev {
    border: 0;
    background-color: white;
    color: black;
    width: 40px;
    height: 40px;
    border-radius: 50%;
}

.swiper-button-prev:after, .swiper-button-next:after{
    font-size: 14px;
    font-weight: 900;
}
@media screen and (max-width: 768px) {
    .slider-wrapper .swiper-button-next, .slider-wrapper .swiper-button-prev {
        width: 20px;
        height: 20px;
    }
    .swiper-button-prev:after, .swiper-button-next:after{
        font-size: 9px;
        font-weight: 900;
    }
}



.slider-wrapper .swiper-pagination-bullet-active {
    width: 20px;
    border-radius: 5px;
    background: var(--secondary-color) !important;
}

.slider-wrapper .swiper-pagination-bullet {
    background: #FFFFFF;
}

.slider-wrapper .swiper-pagination {
    /*top: calc(100% - 100px) !important;*/
}

@media (max-width: 968px) {
    .slider-wrapper .slide .caption {
        top: 15px;
        width: 52.5%;
        left: 45vw;
    }

    .slider-wrapper .slide .caption h2 {
        font-size: 14px;
    }

    .slider-wrapper .slide .caption h3 {
        font-size: 16px;
    }

    .slider-wrapper .slide .caption p {
        font-size: 12px;
    }

    .slider-wrapper .swiper-pagination {
        top: calc(100% - 45px) !important;
    }
}
