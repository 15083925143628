.footer-wrapper {
    padding: 36px 0;
    background-color: var(--primary-color);
}

.footer-layout {
    display: flex;
    gap: 40px 110px;

}

.footer-links {
    display: block;
    padding-top: 16px;
    border-top: 1px solid var(--primary-light-color);
}

.footer-logo-wrapper {
    max-width: 288px;

}

.footer-row {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
}

.footer-item{
    margin-bottom: 20px;
}

.footer-info-item{
    margin-bottom: 20px;
    display: flex;
    gap: 8px;
}

.footer-info-badge {
    display: flex;
    padding: 8px 4px;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    background-color: #e81e02;
    min-width: 36px;
}


.footer-item-links{
    color: var(--background-color) !important;
    text-decoration: none !important;
}

.footer-tjk-info{
    border: 1px solid #336c68;
    padding: 8px;
    border-radius: 5px;
    margin-top: 32px;
    margin-bottom: 18px;
    column-gap: 8px;
}

.footer-faq{
    margin-left: 8px;
    position: relative;
}

.footer-faq-title{
    margin-bottom: 30px;
}

.footer-etbis{
    position: absolute;
    bottom: 18px;
    right: 0;
    /*border: 3px solid var(--background-color);*/
    border-radius: 2px;
}

.footer-link-left{
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: space-between;
}





.mobile-footer {
    display: flex;
    bottom: 0;
    position: fixed;
    width: 100%;
    z-index: 10;
    height: 66px
}

.mobile-footer .mobile-footer-menu {
    background-color: var(--primary-dark-color);
    border-top: 4px solid var(--secondary-color);
    display: flex;
    align-self: stretch;
    justify-content: space-between;
    width: 100%
}

.mobile-footer .mobile-footer-menu .mobile-footer-menu-toggle-btn {
    height: unset;
    width: 100%;
    cursor: pointer;
    flex: 1 1;
    padding: 11px 8px;
    border-radius: unset
}

@media screen and (max-width: 330px) {
    .mobile-footer .mobile-footer-menu .mobile-footer-menu-toggle-btn {
        padding:11px 6px
    }
}

.mobile-footer .mobile-footer-menu .mobile-footer-menu-toggle-btn.isActive {
    background-color: var(--secondary-color);
    color: var(--text-color);
    font-weight: var(--font-weight-bolder)
}

.mobile-footer .mobile-footer-menu .mobile-footer-menu-toggle {
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 6px
}

.mobile-footer .mobile-footer-menu .mobile-footer-menu-toggle.isActive {
    color: var(--text-color);
    font-weight: var(--font-weight-bolder)
}

.mobile-footer-menu-item {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 11px 8px;
    height: 100%;
    color: var(--background-color);
    font-weight: var(--font-weight-medium);
    cursor: pointer;
    transition: all .3s ease-in;
    font-weight: var(--font-weight-regular);
    flex: 1 1
}

@media screen and (max-width: 330px) {
    .mobile-footer-menu-item {
        padding:11px 6px
    }
}

.mobile-footer-menu-item.isActive {
    background-color: var(--secondary-color);
    color: var(--text-color);
    font-weight: var(--font-weight-bolder)
}

.mobile-footer-menu-item-link {
    color: var(--background-color);
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 6px;
    font-size: var(--font-size-xxxs);
    line-height: var(--line-height-xxxs);
    text-align: center
}

.mobile-footer-menu-item-link.isActive {
    color: var(--text-color);
    font-weight: var(--font-weight-bolder)
}


@media screen and (max-width: 768px) {
    .footer-wrapper {
        padding: 24px 0 90px;

    }

    .footer-layout {
        gap: 46px;
        display: block;
    }
    .footer-row {
        margin-top: 30px;
        grid-template-columns: 1fr 1fr;
    }

    .footer-link-left {
        flex-direction: column;
        row-gap: 5px;
        align-items: normal;
    }

    .footer-etbis {
        position: static;
    }
}

@media screen and (max-width: 1024px) {
    .footer-layout {
        gap: 40px 10px;
        grid-template-columns: 1fr 1fr 1fr;
    }
}

