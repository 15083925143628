.betslip {
    background: var(--bs-white);
    border-radius: var(--bs-border-radius-xl);
    padding: 1rem;
}

.betslip h3 {
    font-size: 1rem;
    font-weight: normal;
}

.betslip h2 {
    font-size: 1rem;
    font-weight: 600;
    color: #1E3D98;
    margin-bottom: 0;
}

.betslip .body {
    display: flex;
    margin-top: 1rem;
}

.betslip ul {
    list-style: none;
    margin: 0;
    padding: 0;
    margin-right: 2px;
}

.agf-horse {
    border: 1px solid #198754;
    color: #198754;
}

.betslip ul li {
    width: 29px;
    height: 30px;
    /*border: 1px solid #646BDD75;*/
    background: #D0D3F5;
    border-radius: 5px;
    text-align: center;
    justify-content: center;
    align-items: center;
    margin-bottom: 2px;
    display: flex;
    font-weight: 500;
    cursor: pointer;
}

.betslip ul:not(.emptyColumn) li.column-no {
    background: var(--bs-warning);
    color: var(--bs-dark);
    font-weight: 600;
}

.betslip ul.emptyColumn li.column-no {
    background: var(--bs-gray-200);
    color: var(--bs-gray-500);
    cursor: not-allowed;
}

.betslip ul:not(.emptyColumn) li.system, .betslip ul li.all {
    background: #646BDD75;
    color: var(--bs-dark);
}

.betslip ul.emptyColumn li.system, .betslip ul.emptyColumn li.all, .betslip ul li.emptyHorse {
    background: var(--bs-gray-200);
    color: var(--bs-gray-500);
    cursor: not-allowed;
}

.betslip ul li.horse-added {
    background: #646BDD;
    color: var(--bs-white);
    font-weight: bold;
}


.betslip ul li.system.horse-added {
    background: var(--bs-danger) !important;
    color: var(--bs-white) !important;
    font-weight: bold;
}

.betslip ul li.all.selected-all {
    background: var(--bs-danger) !important;
    color: var(--bs-white) !important;
    font-weight: bold;
}


.betslip .footer {
    margin-top: 1rem;
}

.betslip .footer > div span {
    text-align: right;
    display: block;
    width: 60%;
    font-weight: 700;
    font-size: 1rem;
}

.betslip .footer > div label {
    width: 40%;
    font-weight: 600;
    display: flex;
    justify-content: left;
    align-items: center;
}

.betslip .footer > div input:not([type=checkbox]) {
    width: 60%;
    border-radius: 5px;
    border: 1px solid #646BDD75;
    padding: 0.5rem;

}

.betslip .footer > div input[type=checkbox] {
    margin-left: auto;
}

.betslip .footer > div {
    display: flex;
    border-top: 1px solid #646BDD75;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
}

.betslip .footer > div.actions {
    display: flex;
    justify-content: space-between;
}

.betslip .footer > div button.button-clear {
    width: 20%;
    margin-right: 5px;
    background: var(--bs-danger);
}

.betslip .footer > div button.button-clear img {
    filter: invert(1);
}

.betslip .footer > div button.button-save {
    width: 20%;
    margin-right: 5px;
    background: var(--bs-gray-500);
}

.footer > div button.button-play {
    width: 60%;
    background: var(--bs-warning);
}

.footer > div button.button-play:disabled {
    background: var(--bs-gray-500);
}

.footer > div button.button-play.share {
    width: 60%;
    background: var(--bs-primary);
    color: #FFFFFF;
}

.betslip .footer > div button {
    border-radius: 5px;
    border: none;
    font-weight: 600;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
}

.betslip .footer > div button img {
    width: 20px;
    height: 20px;
}


.sortable-horse-helper {
    background: #646BDD;
    color: var(--bs-white);
    font-weight: bold;
    list-style: none;
    margin: 0;
    display: flex !important;
    justify-content: left;
    align-items: center;
    border-radius: 5px;
    padding-left: 10px;
}

.sortable-horse-helper span {
    display: block;
    width: calc(100% - 30px);
    text-align: left;
}


.betslip .sortable-horses li span {
    display: block;
    width: calc(100% - 25px);
    text-align: left;
    font-size: 12px;
}

.betslip .sortable-horses li {
    width: 100%;
    display: flex;
    justify-content: left;
    align-items: center;
    padding-left: 5px;
}

.betslip .sortable-horses {
    width: 100%;
}


@media (max-width: 968px) {
    .betslip-body {
        height: 45vh;
        overflow-y: scroll;
        overflow-x: hidden;
    }
}

.ebetslip.closed {
    position: fixed;
    bottom: 20px;
    right: 30px;
    background: var(--primary-color);
    min-width: 130px;
    border-radius: 16px;
    z-index: 12;
    padding: 0 6px 6px;
    box-shadow: 0 10px 20px 0 rgba(0, 56, 52, .15);
    border: 4px solid #fff;
    cursor: pointer;
}

.ebetslip.open {
    position: fixed;
    bottom: 0;
    right: 30px;
    background: var(--primary-color);
    min-width: 330px;
    border-radius: 16px 16px 0 0;
    z-index: 3;
}


.mini-coupon-wrapper {
    padding: 9px 8px;
    border-radius: 10px;
    background: var(--secondary-color);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 6px;
}

.coupon-wrapper {
    padding: 10px 10px 16px;
}

.coupon-header {
    border-radius: 8px 8px 0 0;
    background-color: var(--secondary-color);
    padding: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.hide-button {
    background: #fff8b0;
    padding: 6px 12px;
    border-radius: 6px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}

.coupon-body {
    background-color: #f7f8f5;
    width: 100%;
    border-radius: 0 0 10px 10px;
    overflow: auto;
    max-height: 50vh;
}

.coupon-subtitle {
    display: flex;
    align-items: center;
    padding: 6px;
    flex-wrap: wrap;
}

.slip-icon-wrapper {
    width: 40px;
}

.slip-icon {
    background: var(--secondary-color);
    padding: 4px;
    width: -moz-fit-content;
    border-radius: 15px;
    width: 25px;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 5px;
}

.slip-item {
    width: 100%;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #e8e6d0;
    background: #fff;
}

.slip-item > .horse-no {
    padding: 16px 10px;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.slip-item > .horse-name {
    width: 100%;
    padding: 16px 10px;
    border-left: 1px solid #e8e6d0 !important;
    border-right: 1px solid #e8e6d0 !important;
}

.slip-item > .delete {
    padding: 16px 10px;
    border-radius: 0;
    border: none !important;;
}

.slip-item-sort {
    display: flex;
    align-items: center;
    justify-content: center;
    border-right: 1px solid #e8e6d0 !important;
    padding: 16px 10px;
    border-radius: 0;

}

.error-wrapper {
    display: flex;
    align-items: center;
    padding: 6px;
    background: #5c807d;
    margin-top: 10px;
    gap: 4px;
    border-radius: 6px;
}

.selected-horse-list {
    display: flex;
    align-items: center;
    padding: 10px 0;
    border-bottom: 1px solid #33605d;
}


.slip-balance-wrapper {
    display: flex;
    align-items: center;
    padding: 10px 0;
}

.balance-left {
    display: flex;
    width: 100%;
    justify-content: space-between;
    padding-right: 10px;
    border-right: 1px solid #33605d;
}

.balance-right {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    padding-left: 10px;
}

.slip-action-wrapper {
    display: flex;
    gap: 1px;
}

.action-delete {
    padding: 15px 12px !important;
    border: 1px solid #33605d !important;
    background: #33605d !important;
    border-radius: 10px 0 0 10px !important;
}

.action-save {
    padding: 15px 12px !important;
    border: 1px solid #33605d !important;
    background-color: #33605d !important;
    border-radius: 0 !important;
}

.action-multipy-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 1px solid #33605d !important;
    background-color: #33605d !important;
    width: 50%;
}
.multipy-input{
    width: 100%;
    touch-action: manipulation;
    border: none;
    background: transparent;
    color: #fff;
    text-align: center;
    font-size: 13px;
    outline: 0;
}

.play-button{
    padding: 15px 8px;
    border: 1px solid #33605d !important;
    background-color: #33605d !important;
    border-radius: 0 10px 10px 0 !important;
    display: flex !important;
    flex-direction: column !important;
    justify-content: center !important;
    align-items: center !important;
    width: 50%;
}

.play-active{
    background-color: var(--secondary-color) !important;
    color: #353535 !important;
}

.play-active span{
    color: #353535 !important;
}


.betslip-mobile-coupon {
    position: fixed;
    bottom: 0;
    right: 30px;
    background: var(--primary-color);
    min-width: 330px;
    border-radius: 16px 16px 0 0;
    z-index: 3;
}

.betslip-mobile-coupon-wrapper {
    padding: 10px 10px 16px;
}
.betslip-mobile-coupon-error-wrapper {
    display: flex;
    align-items: center;
    padding: 6px;
    background: #5c807d;
    margin-top: 10px;
    gap: 4px;
    border-radius: 6px;
}

.betslip-mobile-selected-horse-wrapper {
    border-bottom: 1px solid #33605d;
    padding: 6px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    background: #33605d;
    border-radius: 10px;
}

.betslip-mobile-selected-horse-wrapper-active {
    border-radius: 10px 10px 0 0;
    background: var(--secondary-color);
    border-color: var(--secondary-color);
}

.betslip-mobile-selected-horse-wrapper-active .betslip-mobile-show-coupon-btn {
    background: #fff8b0;
}

.betslip-mobile-selected-horse-wrapper-active .slip-icon {
    background: #000;
}

.betslip-mobile-selected-horses {
    display: flex;
    align-items: center;
    padding: 10px 0;
    border-bottom: 1px solid #33605d;
}

.betslip-mobile-icon-wrapper {
    width: 100%;
}

@media screen and (max-width: 1024px) {

}
.betslip-mobile-show-coupon-btn {
    background: #fff8b0;
    padding: 6px 12px;
    border-radius: 6px;
    cursor: pointer;
}
.betslip-mobile-show-coupon-btn {
    display: flex;
    align-items: center;
    justify-content: center;
}

.betslip-mobile-balance-wrapper {
    display: flex;
    align-items: center;
    padding: 10px 0;
}

.betslip-mobile-action-wrapper {
    display: flex;
    gap: 1px;
}
.betslip-mobile-debit-balance {
    display: flex;
    width: 100%;
    justify-content: space-between;
    padding-right: 10px;
    border-right: 1px solid #33605d;
}
.betslip-mobile-point-balance {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    padding-left: 10px;
}

@media screen and (max-width: 1024px) {
    .betslip-mobile-show-coupon-btn {
        background: var(--secondary-color);
    }
    .betslip-mobile-coupon {
        right: 0;
        min-width: 100%;
    }
    .betslip-mobile-coupon-error-wrapper {
        margin-bottom: 10px;
        margin-top: 0;
    }
    .betslip-mobile-selected-horses {
        border-bottom: none;
        padding: 0;
    }
}



.betting-mobile-selected-horse {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}



.betting-mobile-point-checkbox {
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    border-radius: 4px !important;
    border: 3px solid #33605d !important;
    width: 18px !important;
    height: 18px !important;
}

.point-selected {
    background: #33605d !important;
    border-width: 0;
    padding: 0;
}

@media screen and (max-width: 768px) {
    .betting-mobile-point-checkbox {
        width: 22px !important;
        height: 22px !important;
        border-radius: 6px !important;
    }
}
