.withdraw-page-wrapper {
    border-radius: 15px;
    background-color: var(--background-light-color);
}

.withdraw-title {
    background-color: var(--primary-color);
    color: var(--background-color);
    border-radius: 15px 15px 0 0;
    padding: 12px;
}

.withdraw-form-wrapper {
    padding: 18px 12px;
}

.info-box {
    display: flex;
    align-items: center;
    column-gap: 5px;
    background-color: var(--input-background-color);
    padding: 15px;
    border-radius: 8px;
    margin: 20px 0px;
}

.withdraw-form {
    display: flex;
}

.withdraw-dropdown {
    position: relative;
    display: inline-block;
    width: 100%;
    margin-right: 10px;
}

.dropdown-toggle {
    height: 32px;
    padding: 21px 10px;
    border-radius: 10px;
    width: 100%;
    font-size: var(--font-size-sm);
    color: var(--text-color);
    background-color: var(--input-background-color);
    border: 1px solid var(--input-background-color);
    line-height: 0;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

.dropdown-icon {
    position: relative;
    bottom: 8px;
}

.currency-field-wrapper {
    position: relative;
    margin-bottom: 20px;
    width: 100%;
}

.currency-tl {
    position: absolute;
    top: 39px;
    left: 10px;
}

.currency-field-input {
    height: 32px;
    padding: 21px 20px;
    border-radius: 10px;
    width: 100%;
    font-size: var(--font-size-sm);
    color: var(--text-color);
    background-color: var(--input-background-color);
    border: 1px solid var(--input-background-color);
    touch-action: manipulation;
}

.withdraw-dropdown-menu {
    margin-top: 4px;
    background: #FFFFFF;
    box-shadow: 0 2px 14px 0 rgba(0, 0, 0, .25);
    max-height: 225px;
    overflow: auto;
    border-radius: 10px;
    position: absolute;
    z-index: 1;
    display: flex;
    flex-direction: column;
    width: 100%;
}

.withdraw-dropdown-menu-item {
    display: flex;
    justify-content: space-between;
    padding: 8px;
    background-color: #fff !important;
    cursor: pointer;
    border: 0;
    border-bottom: 1px solid var(--input-background-color) !important;
    margin: 0 10px;
}

.withdraw-long-text {
    text-overflow: ellipsis;
    overflow: hidden;
    width: 320px;
    white-space: nowrap;
}

.withdraw-iban-text {
    color: #929191;
    font-weight: var(--font-weight-light);
}

.withdraw-icon {
    margin: auto 0;
}

.add-bank-account-btn {
    border-bottom: 1px solid var(--input-background-color) !important;
    display: flex !important;
    margin: 10px !important;
}

.withdraw-icon-btn svg {
    margin: auto 3px;
}

.withdraw-icon-btn {
    display: flex;
    padding: 10px 18px;
    margin: auto;
    width: 294px;
    height: 44px;
    justify-content: center;
    align-items: center;
}

.not-found-list svg {
    display: flex;
    justify-content: center;
    margin: auto;
    padding-bottom: 16px;
    width: 64px;
    height: 64px;
}

.not-found-list {
    margin: auto;
    border: 1px solid #e8e6d0;
    background: linear-gradient(0deg, #f7f8f5, #f7f8f5);
    border-radius: 8px;
    padding: 60px 0;
    margin-bottom: 30px !important;
}

.withdraw-add-bank-account-wrapper {
    padding: 36px 12px;
    background-color: var(--background-light-color);
    border-radius: 8px;
}

.withdraw-bank-icon {
    display: flex;
    justify-content: center;
    margin-bottom: 24px;
}

.add-new-bank-account-btn {
    margin: auto !important;
    display: flex !important;
    max-width: 296px !important;
    justify-content: center !important;

}

.add-new-bank-account-btn-icon {
    display: flex;
    padding: 10px 18px;
    margin: auto;
    width: 294px;
    height: 44px;
    justify-content: center;
    align-items: center;
}

.add-new-bank-account-btn-icon svg {
    margin: auto 3px;
}


.withdraw-item {
    border: 1px solid var(--input-background-color) !important;
    border-radius: 10px;
    padding: 10px;
    margin-bottom: 5px;
}

.withdraw-badge {
    background-color: var(--secondary-color);
    border-radius: 6px;
    padding: 4px 8px;
    font-size: 10px !important;
    font-weight: 600 !important;
}

.withdraw-list-text {
    font-size: 10px !important;
}

.cancel-withdraw {
    display: flex;
    justify-content: flex-end;
    margin-top: auto;
}

.cancel-withdraw button {
    font-size: 12px;
    height: 33px;
    padding: 12px;
    line-height: 1;
}
