header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 4;
    background-color: var(--primary-dark-color);
    border-bottom: 4px solid var(--secondary-color);
}

.nav-logo {
    height: 16px;
}


nav {
    display: flex;
    align-self: stretch;
    gap: 0;
}

.navItem {
    padding: 0 6px;
    font-size: var(--font-size-xs);
    color: var(--background-color);
    font-weight: var(--font-weight-medium);
    cursor: pointer;
    transition: all .3s ease-in;
    text-decoration: none;
    display: flex;
    align-items: center;
}


.login {
    font-family: Arial, sans-serif;
    border-radius: var(--border-radius);
    transition: all .3s;
    text-align: center;
    display: inline-block;
    cursor: pointer;
    position: relative;
    background-color: var(--primary-color);
    color: var(--background-color);
    padding: 11px 24px !important;
    text-decoration: none;
    font-weight: bold;
    font-size: var(--font-size-xs);
    margin: 10px 0 10px 8px;
}

.register {
    font-family: Arial, sans-serif;
    border-radius: var(--border-radius);
    transition: all .3s;
    text-align: center;
    display: inline-block;
    cursor: pointer;
    position: relative;
    background-color: var(--secondary-color);
    color: var(--text-color);
    padding: 11px 24px !important;
    text-decoration: none;
    font-weight: bold;
    font-size: var(--font-size-xs);
    margin: 10px 0 10px 8px;
}

header nav > a.active {
    background-color: var(--secondary-color);
    color: var(--text-color);
    font-weight: 900;
}


.header-profile {
    min-width: 192px;
    width: 100%;
    background-color: transparent !important;
    cursor: pointer;
}

.user-more {
    margin: auto 0 6px 6px;

}

.header-profile-wrapper {
    padding: 10px 0;
    margin-left: auto;
    position: relative;
    transition: display 2s;
}

.user-menu-layout {
    z-index: -999;
    position: absolute;
    height: 100vh;
    width: 100vh;
    left: 0;
    right: 0;
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    background-color: rgba(0, 0, 0, .5);
}

.user-menu {
    position: absolute;
    display: flex;
    transition: all 1s ease-in;
    width: 100%;
    min-width: 608px;
    right: -35%;
    z-index: 9999;
    margin-top: 18px;
    background: #fff;
    box-shadow: 0 4px 10px 0 rgba(0, 25, 48, .051);
    border-radius: 12px;
    border: 1px solid var(--border-light-color);
    padding: 30px 18px
}

.user-menu button {
    width: 100%;
    font-weight: 500;
    text-align: start;
    padding: 3px;
    height: 65px;
    border-radius: 16px !important;
    display: flex;
    margin: auto;
}


@media screen and (max-width: 1024px) {
    .user-menu {
        position: fixed;
        left: 0;
        margin-top: 16px
    }

    .user-menu ul {
        border-radius: 0;
        border: 0
    }

    .user-menu button {
        width: 100%;
        font-weight: 500;
        text-align: center;
        padding: 3px;
        margin: 2px 0
    }
}

.user-menu:after {
    top: -15px;
    border-bottom: 15px solid #fff
}

.user-menu:after, .user-menu:before {
    content: "";
    position: absolute;
    left: calc(100% - 100px);
    width: 0;
    height: 0;
    margin: 0 auto;
    border-left: 15px solid transparent;
    border-right: 15px solid transparent
}

.user-menu:before {
    top: -8px;
    border-bottom: 15px solid transparent
}


@media screen and (max-width: 1024px) {
    .user-menu-layout {
        display: block
    }
}


.menu-balance-left {
    width: 100%;
    border: 1px solid #d4d6ce;
    border-right: 0 !important;
    border-radius: 12px 0 0 12px;
    padding: 8px 12px;
    margin-bottom: 20px
}

.menu-balance-left p {
    border-right: 1px solid #d4d6ce
}

@media screen and (max-width: 1024px) {
    .menu-balance-left {
        margin-bottom: 0
    }
}


.user-menu-links {
    color: var(--background-color);
    display: flex;
    margin: auto 0
}

.user-menu-button-wrapper {
    display: flex;
    margin: auto 0 auto 15px
}

.user-menu-title {
    font-size: 15px !important;
    line-height: 150%
}

.user-menu-desc {
    font-size: 13px !important;
    color: #677489 !important;
    line-height: 150%

}


.menu-balance-right {
    width: 100%;
    margin-bottom: 20px;
    border: 1px solid #d4d6ce;
    border-left: 0 !important;
    border-radius: 0 12px 12px 0;
    padding: 8px 12px
}

@media screen and (max-width: 1024px) {
    .menu-balance-right {
        margin-bottom: 0
    }
}


.header-more {
    display: flex;
    align-items: center;
    padding: 0 6px;
    height: 100%;
    font-size: var(--font-size-xs);
    color: var(--background-color);
    font-weight: var(--font-weight-medium);
    cursor: pointer;
    transition: all .3s ease-in;
}

.header-more.isOpen {
    background-color: #336c68 !important;
}

.navigation-menu {
    position: absolute;
    align-items: center;
    height: 100%;
    width: 180px;
    height: 120px;
    background-color: #336c68;
    font-size: var(--font-size-xs);
    color: var(--background-color);
    font-weight: var(--font-weight-medium);
    cursor: pointer;
    transition: all .3s ease-in;
}

.navigation-menu-item {
    display: flex;
    align-items: center;
    padding: 0 10px;
    height: 100%;
    border-bottom: 4px solid var(--secondary-color);
    width: 180px;
    height: 60px;
    background-color: #336c68;
    font-size: var(--font-size-xs);
    color: var(--background-color);
    font-weight: var(--font-weight-medium);
    cursor: pointer;
    transition: all .3s ease-in;
}

.header-link {
    font-size: var(--font-size-xs);
    font-weight: var(--font-weight-medium);
    color: var(--background-color);
    text-decoration: none;
}


.breadcrumb-wrapper {
    background-color: var(--primary-dark-color);
    /*border-bottom: 1px solid #33605d;*/
    margin-top: 60px;
}

.breadcrumb-wrapper .breadcrumb-list {
    display: flex;
    margin: 0 auto;
    /*max-width: 1064px;*/
    padding: 8px 12px;
    column-gap: 2px;
    align-items: center;
}

.breadcrumb-list-item {
    font-size: var(--font-size-xxs);
    font-weight: var(--font-weight-regular);
    color: hsla(0, 0%, 100%, .502);
    line-height: normal;
    text-decoration: none;
}

.breadcrumb-list-item.is-active {
    color: #fff;
    font-weight: var(--font-weight-medium);
}


.header-navigation-menu {
    display: flex;
    align-self: stretch;
    gap: 0;
    margin-left: 42px;

}

@media screen and (max-width: 768px) {
    .header-navigation-menu {
        display: none;
    }
}

@media screen and (max-width: 1024px) {
    .header-navigation-menu {
        margin-left: 5px;
    }
}

@media screen and (max-width: 1024px) {
    .header-navigation-menu {
        margin-left: 5px
    }

    .header-navigation-menu .Header_navigationMenuItem__H_u1L {
        padding: 0 5px
    }

    .header-navigation-menu .Header_navigationMenuItem__H_u1L .Header_headerItemLinks__Ps6EQ {
        font-size: 11px
    }
}


.mobile-menu-wrapper {
    position: fixed;
    top: 58px;
    margin-left: auto;
    transition: display 2s;
    width: 100%;
    right: 0;
    border-radius: 0;
    margin-top: 1px;
    background: #fff;
    box-shadow: 0 4px 10px 0 rgba(0, 25, 48, .051);
    border: 1px solid var(--border-light-color);
    height: -webkit-fill-available;
    overflow: auto;
    z-index: 9
}

.mobile-menu-wrapper ul li {
    padding: 18px 16px
}

.mobile-menu-wrapper ul li:not(:last-child) {
    border-bottom: 1px solid #e2e2e2
}

.mobile-menu-wrapper ul li p {
    font-size: 15px !important
}

.mobile-menu-wrapper .mobile-menu-button-wrapper {
    display: flex;
    justify-content: space-between
}

.mobile-menu-wrapper .mobile-menu-button-wrapper a {
    text-decoration: none;
    color: var(--text-color)
}

.mobile-menu-wrapper .mobile-menu-button-wrapper p {
    margin: auto 0;
    text-decoration: none !important
}

.mobile-menu-wrapper .mobile-menu-button-wrapper svg {
    margin-right: 8px
}

.mobile-menu-wrapper .mobile-menu-title {
    padding: 8px 16px;
    background: #f0f1ec
}

.mobile-menu-wrapper .mobile-menu-title p {
    color: #7d7d7d;
    font-size: 12px;
    font-weight: 500;
    margin: auto
}


.balance-wrapper-mobile {
    display: flex;
    padding: 12px 16px 0 12px;
}


.mobile-menu-wrapper .mobile-menu-button-wrapper a {
    text-decoration: none;
    color: var(--text-color);
}

.mobile-menu-item-link {
    display: flex;
    width: 100%;
    justify-content: inherit;
}

.auth-btn-wrapper {
    padding: 10px 0;
    margin-left: auto;
    position: relative;
    transition: display 2s;
}

.header-mobile-menu-btn {
    height: unset !important;

    background-color: transparent !important;
    padding: 5px 7px 3px !important;
    border-radius: var(--border-radius);
    display: none !important;
    cursor: pointer;
}


.header-sign-up-btn {
    background-color: var(--primary-light-color);
    padding: 11px 24px !important;
    height: unset !important;
}


.header-login-btn {
    padding: 11px 24px !important;
    margin-right: 8px;
    height: unset !important;
}

@media screen and (max-width: 1024px) {
        .header-sign-up-btn {
        padding: 12px 9px !important;
        margin-right: 3px;
    }

    .header-login-btn {
        padding: 12px 9px !important;
        margin-right: 5px;
    }
}

@media screen and (max-width: 768px) {
    .header-mobile-menu-btn {
        display: inline-block !important;
    }

    .header-sign-up-btn {
        padding: 11px 24px !important;
        margin-right: 8px;
    }

    .header-login-btn {
        padding: 11px 24px !important;
        margin-right: 8px;
    }
}

