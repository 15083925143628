.agreement-wrapper {
    display: grid;
    grid-template-columns: 220px 1fr;
    grid-gap: 20px;
    gap: 20px;
    padding-top: 20px;
    height: calc(100vh + 100px);
    position: relative;
    z-index: 3;

}

.agreement-menu-wrapper {
    display: flex;
    flex-direction: column;
}

.agreement-menu-button{
    height: 33px !important;
    border-radius: 0 !important;
    background-color: transparent !important;
    text-align: left !important;
    padding-left: 10px;
    font-size: 12px !important;
    color: var(--text-color) !important;
}

.agreement-menu-button.active{
    background: linear-gradient(90deg, var(--secondary-color) 2%, var(--primary-color) 0) !important;

}

.agreement-menu-button.active p {
    color: var(--background-color) !important;
    font-size: 12px !important;
    font-weight: 500 !important;
}

.agreement-page-wrapper{
    height: auto;
    overflow-y: auto;
}

.agreement-menu-overlay{
    position: fixed;
    z-index: -1;
    left: 0;
    bottom: 0;
    width: calc(50vw - 300px);
    background-color: #eee;
    height: calc(100vh + 100px);
}
