.deposit-wrapper {
    border-radius: 15px;
    background-color: var(--background-light-color);
}

.deposit-tittle {
    background-color: var(--primary-color);
    color: var(--background-color) !important;
    border-radius: 15px 15px 0 0;
    padding: 12px;
}

.deposit-section {
    padding: 10px 15px;
}

.deposit-info {
    font-size: 11px;
    margin-bottom: 8px;
    font-weight: var(--font-weight-light);
}


.deposit-description {
    margin: 3px 0;
    cursor: pointer;
    font-size: 12px;
}

.deposit-copy {
    background-color: var(--border-color);
    border-radius: 20px;
    padding: 4px 8px;
    font-size: 10px;
    margin: auto 3px;
    display: flex;
    color: var(--text-color);
    font-weight: 500;
    cursor: pointer;
}

.accordion {
    transition: background-color .2s;
    cursor: pointer;
    border-radius: 8px;
}

.accordion-wrapper {
    padding: 15px;
    width: 100%;
    border-radius: 8px;
    margin-bottom: 12px;
    background-color: var(--background-light-color);
}

.accordion-question {
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    align-items: center;
}

.accordion-divider {
    background-color: #929191;
    width: 100%;
    height: 1px;
}

.copy-tooltip {
    transition: all 2s;
    display: flex;
    background-color: var(--secondary-color);
    margin: auto 0;
    padding: 4px 6px;
    border-radius: 50px;
    position: absolute;
    justify-content: center;
    align-items: center;

}

.copy-tooltip svg {
    margin-right: 2px
}
