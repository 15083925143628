.hippodrome-widgets {
    display: flex;
    align-items: center;
    gap: 8px;
    height: max-content;
    position: relative;
    z-index: 1;
    overflow: scroll;
    /*justify-content: center;*/
}

.hippodrome-item {
    padding: 12px 8px 8px 12px;
    background-color: var(--primary-light-color);
    border-radius: 10px 10px 0 0;
    min-width: 167px;
    cursor: pointer;
    position: relative;
    letter-spacing: -.3px;
}

.hippodrome-item p {
    color: #FFFFFF;
}

.hippodrome-item span {
    color: #FFFFFF !important;
}

.hippodrome-item.selected-hippodrome {
    background-color: var(--background-color);
    padding: 12px 0 8px 16px;
}
.hippodrome-item.selected-hippodrome span {
    color: var(--primary-color) !important;
}

.hippodrome-item.selected-hippodrome p {
    color: var(--primary-color);
}

.selected-indicator{
    width: 8px;
    height: 50px;
    background-color: var(--secondary-color);
    position: absolute;
    top: 13px;
    left: 0;
}

.hippodrome-info-container{
    overflow: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;

}
.hippodrome-info{
    padding: 12px 0;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 4px;

}
@media screen and (max-width: 768px) {
    .hippodrome-info {
        min-width: 450px;
        width: 100%;
        padding: 12px 0;
    }
}
.hippodrome-info-item{
    padding: 6px 8px;
    display: flex;
    align-items: center;
    font-size: var(--font-size-xxs);
    font-weight: var(--font-weight-bold);
    margin-right: 4px;
    background-color: var(--primary-color);
    color: var(--background-color);
    letter-spacing: -.3px;
    border-radius: 30px;

}

.hipodrome-item-text{
    display: flex;
    align-items: center;
    gap: 4px;
}
