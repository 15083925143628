.login-form {
    display: flex;
    flex-direction: column;
    /*padding: 20px;*/
    border-radius: var(--bs-border-radius-xl);
    background: #FFFFFF;
    width: 472px;
    max-width: calc(100% - 20px);
    z-index: 12;
    position: fixed;

    top: 50vh;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%);
}

.btn-close {
    align-self: flex-end;
    text-decoration: none;
}

.form {
    display: flex;
    flex-direction: column;
}


.eye-icon {
    float: right;
    margin-right: 6px;
    margin-top: -13px;
    position: relative;
    z-index: 2;
}


.forget-password-link {
    cursor: pointer;
    text-decoration: underline;
    width: 100%;
    text-align: center;
    margin-top: 1rem;
    font-weight: 400;
    font-size: 14px;
    color: #1E3D98;
}


.modal-form{
    padding: 20px;
}

.modal-header{
    background-color: var(--primary-color);
    display: flex !important;
    justify-content: space-between;
    padding: 20px 18px !important;
    border-radius: 10px 10px 0 0 !important;
}

.btn-login{
    background-color: var(--secondary-color) !important;
    color: var(--text-color) !important;
    font-size: var(--font-size-sm) !important;
    font-weight: 600 !important;
}


.active-selection {
    color: var(--primary-color);
    border: 1px solid var(--primary-color) !important;
    background-color: var(--input-background-color);
}

.forgot-password-options {
    border: 1px solid var(--border-color);
    border-radius: 12px;
    padding: 18px 16px;
    color: var(--primary-color);
    margin-bottom: 16px;
    accent-color: var(--primary-color);
}

.forgot-password-label {
    margin: auto;
    font-size: var(--font-size-sm);
}
.forgot-password-label .forgot-password-info {
    font-weight: var(--font-weight-normal);
}


.password-code-input {
    font-size: 1.5rem;
    letter-spacing: 1rem;
    text-align: center;
}
