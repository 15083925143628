
.member-title {
    font-size: 20px !important;
    font-weight: 800 !important;
    color: #fff !important;
}

.ticket-filter-wrapper {
    display: flex;
    margin: 20px 0;
    column-gap: 5px;
}

.ticket-date-filter {
    z-index: 1;
    cursor: pointer;
    margin-right: 10px;
    background: linear-gradient(180deg, #fff, #f7f8f5);
    border: 1px solid #dad6ce;
    display: flex;
    justify-content: space-between;
    width: 162px;
    height: 44px;
    border-radius: 10px;
}

.filter-disabled {
    z-index: 1;
    cursor: pointer;
    margin-right: 10px;
    background: linear-gradient(180deg, #fff, #f7f8f5);
    border: 1px solid #dad6ce;
    display: flex;
    justify-content: space-between;
    width: 162px;
    height: 44px;
    border-radius: 10px;
}

.filter-disabled {
    filter: opacity(.3) !important;
}

.date-filter-button {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: 4px;
}

.date-filter-button svg {
    margin: auto 10px;
}

.filter-button svg {
    margin: auto 10px;
}

.filter-button {
    margin: 0 5px;
    width: 160px;
    display: flex;
    background-color: #f0f1ec !important;
    border: 0 !important;
}

.filter-button p {
    margin: auto;
    padding: 0 10px;
}

.long-text {
    width: 150px;
}

.ticket-filter-wrapper .filter-button {
    margin: 0 5px;
    width: 160px;
    display: flex;
    background-color: #f0f1ec !important;
    border: 0 !important;
}

.ticket-table {
    width: 100%;
    margin-bottom: 50px;
}

.ticket-table thead {
    text-align: left;
    background-color: var(--primary-color);
    color: #fff;
    font-weight: 600 !important;
    font-size: 11px !important;
}

.ticket-table thead th:first-child {
    border-left: 1px solid #205c58;
}

.ticket-table thead th:not(:last-child) {
    border-right: 1px solid #205c58;
}

.ticket-table thead th {
    padding: 14px 8px;
}

.ticket-table tbody tr:nth-child(odd) {
    background-color: #fff;
}

.ticket-table tbody tr {
    width: 100px;
    border-bottom: 1px solid #e8e6d0;
    border-top: 1px solid #e8e6d0 !important;
}

.ticket-table tbody tr:nth-child(2n) {
    background-color: #f7f8f5;
}

.ticket-table tbody td:first-child {
    width: 150px;
}

.ticket-table tbody td {
    padding: 8px;
    border: 1px solid #e8e6d0 !important;
    min-height: 50px;
}

.ticket-id {
    color: #929191 !important;
    margin-right: 5px;
    gap: 10px;
}

.ticket-table tbody td svg {
    width: 15px !important;
    height: 15px !important;
    margin: auto 0;
}

.ticket-bet-type {
    width: 120px;
}

.ticket-detail-button {
    background-color: #d6dfde;
    display: flex;
    justify-content: center;
    height: 100%;
    flex-direction: column;
    border: 0 !important;
    width: 55px;
    cursor: pointer;
}

.ticket-table tbody .ticket-detail-button svg {
    margin: auto;
}


.ticket-play-or-delete {
    display: flex;
    flex-wrap: nowrap;
    align-items: stretch;
    justify-content: center;
    width: 100%;
}

.play-saved-ticket {
    height: 32px !important;
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
    width: 88px !important;
}

.delete-saved-ticket {
    height: 32px;
    cursor: pointer;
    border-radius: 8px;
    margin-left: -1px;
    border: 1px solid #ced4da;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    width: 44px;
}

.delete-saved-ticket svg {
    width: 15px !important;
    height: 17px !important;
    margin: auto 0;
}


.pagination-wrapper {
    margin-bottom: 150px;
}

.pagination-wrapper ul {
    display: flex;
    justify-content: space-between;
}


.pagination-wrapper ul .pagination-prev {
    box-shadow: 0 1px 2px 0 rgba(16, 24, 40, .05);
    background: #fff;
    border: 1px solid #d4d6ce;
    padding: 10px 18px;
    border-radius: 8px;
    font-size: 12px;
    font-weight: 600;
    color: #353535;
    width: 100px;
    text-align: center;
    justify-content: center;
    display: flex;
    align-items: center;
}

.pagination-wrapper ul .pagination-prev svg {
    transform: rotate(180deg);
    margin: auto;
}

.pagination-wrapper ul .disable-page svg {
    stroke-opacity: .3;
}

.pagination-wrapper ul .pagination-prev p {
    margin-left: 3px;
}

.pagination-wrapper ul .page {
    font-size: 12px;
    font-weight: 400;
    margin: auto 0;
}

.pagination-wrapper ul .selected-page {
    width: 36px;
    height: 36px;
    border-radius: 46px;
    background: #f0efe1;
    font-size: 12px;
    font-weight: 700;
    display: flex;
    justify-content: center;
    margin: auto;
    padding: 11px 14px;
    color: #000000;
}


.pagination-wrapper ul .page-next {
    box-shadow: 0 1px 2px 0 rgba(16, 24, 40, .05);
    background: #fff;
    border: 1px solid #d4d6ce;
    padding: 10px 18px;
    border-radius: 8px;
    font-size: 12px;
    font-weight: 600;
    color: #353535;
    width: 100px;
    text-align: center;
    justify-content: center;
    align-items: center;
    display: flex;
}

.pagination-wrapper ul .page-next p {
    margin-right: 3px;
}


.pagination-wrapper ul .page-next svg {
    margin: auto;
}

.pagination-wrapper ul .disable-page-button {
    color: #d4d6ce !important;
    font-size: 12px !important;
    font-weight: 500 !important;
    cursor: not-allowed;
}

.ticket-modal-body {
    padding: 0 24px 24px;
    max-height: calc(100vh - 160px);
    overflow: hidden !important;
    width: 100%;
}

.ticket-detail-row {
    display: flex;
    justify-content: space-between;
    padding: 3px 0;
}

.detail-legs {
    display: flex;
    overflow: scroll;
}

.leg-button {
    padding: 1px 11px;
    width: 64px;
    margin-right: 3px;
    margin-bottom: 3px;
    background-color: #f0f1ec !important;

}


.leg-button p {
    text-align: start;
    color: var(--text-color);
}


.leg-button.selected {
    background-color: var(--secondary-color) !important;
}

.selected-legs {
    height: auto;
    overflow: auto;
    max-height: 450px;
    margin-top: 20px;
    padding: 0;
}

.selected-legs li {
    border: 1px solid rgba(0, 15, 15, .118) !important;
    padding: 4px 5px;
    color: #353535;
    font-weight: 600;
    font-size: 11px;
}

.selected-legs li:nth-child(odd) {
    background-color: #fff !important;
}

.selected-legs li:nth-child(2n) {
    background-color: #f0f1ec !important;
}


.member-wrapper {
    display: grid;
    grid-template-columns: 220px 1fr;
    grid-gap: 20px;
    gap: 20px;
    padding-top: 20px;
    height: calc(100vh + 100px);
    position: relative;
    z-index: 3;
}

@media screen and (max-width: 1024px) {
    .member-wrapper {
        display: block;
        padding-top: 0;
        height: 100%;
    }
}


.member-wrapper-overlay {
    position: fixed;
    z-index: -1;
    left: 0;
    bottom: 0;
    width: calc(50vw - 425px);
    background-color: #eee;
    height: calc(100vh + 100px);
}

.member-menu-wrapper {
    display: flex;
    flex-direction: column;
}

.member-menu-wrapper button {
    height: 33px;
    border-radius: 0;
    background-color: transparent !important;
    text-align: left;
    padding-left: 10px;
    font-size: 12px;
}

.member-menu-item {
    height: 33px !important;
    border-radius: 0 !important;
    background-color: transparent !important;
    text-align: left !important;
    padding-left: 10px !important;
    font-size: 12px !important;
}

.member-menu-item.active {
    background: linear-gradient(90deg, var(--secondary-color) 2%, var(--primary-color) 0) !important;
}

.member-menu-item.active p {
    color: var(--background-color) !important;
    font-size: 12px !important;
    font-weight: 500 !important;
}

.member-page-wrapper {
    height: auto;
    overflow-y: auto;
}

@media screen and (max-width: 1024px) {
    .member-page-wrapper {
        margin-top: 20px;
    }
}


.member-mobile-menu-wrapper {
    background-color: var(--primary-color);
    margin: 0 -12px
}

.member-mobile-menu-wrapper .member-mobile-menu-options {
    border-radius: 6px 6px 0 0;
    padding: 10px;
    margin: 10px 0 0 12px;
    font-size: 12px;
    height: 34px;
    background-color: var(--border-green-color);
    font-weight: 600;
    color: var(--background-color) !important;
    width: 100%;
    min-width: -moz-fit-content;
    min-width: fit-content
}

.member-mobile-menu-wrapper .member-mobile-menu-active {
    font-weight: 600;
    color: var(--primary-color) !important;
    background: linear-gradient(90deg, var(--secondary-color) 5%, var(--background-color) 0) !important;
    padding: 10px 20px 10px;
}

.member-mobile-menu-inner-wrapper {
    display: flex;
    overflow-x: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none
}

.member-mobile-menu-inner-wrapper::-webkit-scrollbar {
    display: none
}

.member-mobile-menu-logout {
    background-color: transparent !important;
    margin-left: auto
}

.member-mobile-menu-settings {
    width: 100%;
    padding-right: 2px
}

.bank-accounts-icon-btn {
    display: flex;
    padding: 10px 18px;
    margin: auto;
    width: 100%;
    height: 44px;
    justify-content: center;
    align-items: center;
}

.bank-accounts-icon-btn svg {
    margin: auto 3px;
}

.bank-account-list {
    border: 1px solid var(--input-background-color);
    padding: 12px;
    border-radius: 8px;
    margin-bottom: 24px;
}

.list-bank-code {
    margin: auto;
}

.list-bank-code img {
    margin: auto;
    display: flex;
    justify-content: center;
}

.list-iban {
    border-left: 1px solid var(--input-background-color);
    margin: auto 0px;
    padding-left: 10px;
}

.bank-account-long-text {
    text-overflow: ellipsis;
    overflow: hidden;
    width: 400px;
    white-space: nowrap;
}

.iban-color {
    color: rgb(146, 145, 145) !important;
}

.default-bank {
    padding: 4px 8px;
    background-color: var(--secondary-color);
    border-radius: 4px;
    height: 16px;
    line-height: 0.9;
    font-weight: 500;
    margin: auto 5px;
    color: var(--primary-color) !important;
}

.edit-bank {
    display: flex;
    justify-content: flex-end;
}


.iban-input-wrapper {
    position: relative;
    margin-bottom: 20px;
}

.modal-iban-input {
    height: 34px;
    width: 100%;
    padding: 22px 10px;
    border-radius: 10px;
    color: var(--text-color);
    background-color: var(--input-background-color);
    touch-action: manipulation;
}

.modal-input-wrapper {
    position: relative;
    margin-bottom: 20px;
    width: 100%;
}

.modal-input {
    height: 32px;
    padding: 21px 10px;
    border-radius: 10px;
    width: 100%;
    font-size: var(--font-size-sm);
    color: var(--text-color);
    background-color: var(--input-background-color);
    border: 1px solid var(--input-background-color);
}

.modal-checkbox-wrapper {
    margin-bottom: 16px !important;
}

.modal-checkbox-input-wrapper {
    display: grid;
    grid-template-columns: 12px 1fr;
    gap: 16px;
    margin-bottom: 4px;
}

.modal-checkbox {
    display: none;
}

.modal-checkbox + label {
    display: inline-block;
    position: relative;
    cursor: pointer;
}

.modal-checkbox:checked + label::before {
    background-color: var(--primary-color);
    border: 1px solid var(--primary-color);
}

.modal-checkbox:checked + label::after {
    content: url(../../assets/icons/check.svg);
    position: absolute;
    left: 4px;
    top: 3px;
    font-size: 14px;
    color: rgb(255, 255, 255);
}

.transaction-filter-wrapper {
    display: flex;
    justify-content: space-between;
    background-color: var(--primary-color);
    padding: 12px 16px;
    border-radius: 8px 8px 0 0;
    width: 100%;
}

.transactions-balance {
    display: flex;
    margin: auto 0;
}

.transactions-balance-debit {
    display: flex;
}

.transactions-balance-point {
    display: flex;
    border-left: 1px solid #336c68;
}

.transactions-balance-point svg {
    margin-left: 10px;
}

.transaction-filter {
    display: flex;
}

.transaction-filter-dropdown {
    position: relative;
    display: inline-block;
    margin-right: 12px;
    min-width: 174px;
}

.transaction-filter-dropdown-toggle {
    height: 42px;
    min-width: 174px;
    padding: 5px 8px;
    border-radius: 10px;
    width: 100%;
    font-size: var(--font-size-sm);
    color: var(--text-color);
    background-color: var(--border-green-color);
    display: flex;
    justify-content: space-between;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border: 0;
    align-items: center;
}

.transaction-filter-dropdown-title {
    font-size: 11px !important;
    font-weight: 700 !important;
    margin-bottom: 2px;
    text-align: left;
}

.transaction-filter-dropdown-value {
    font-size: 10px !important;
    display: flex;
    width: 116%;
}

.transaction-dropdown-icon {
    margin: auto 0;
}

.transaction-date-filter {

}

.date-picker-button {
    cursor: pointer;
    position: relative;
}

.transaction-dropdown-outer {
    width: 100%;
    height: 100vh;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 1;
}

.transaction-dropdown-menu {
    margin-top: 4px;
    background: #FFFFFF;
    box-shadow: 0 2px 14px 0 rgba(0, 0, 0, .25);
    border-radius: 10px;
    position: absolute;
    z-index: 2;
    display: flex;
    flex-direction: column;
    width: 100%;
}

.transaction-dropdown-menu-item {
    display: flex;
    justify-content: space-between;
    padding: 8px;
    border: 0;
    border-bottom: 1px solid var(--input-background-color) !important;
    margin: 0 10px;
    background-color: #fff !important;
    cursor: pointer;
}

.transaction-dropdown-menu-item button {
    border-bottom: 1px solid var(--input-background-color) !important;
    margin: 0 10px;
}

@media screen and (max-width: 1024px) {
    .transaction-filter-wrapper {
        flex-direction: column;
        justify-content: center;
    }

    .transaction-filter-wrapper .transactions-balance {
        justify-content: center;
    }

    .transaction-filter-wrapper .transactions-balance-debit {
        margin-left: auto;
        width: 100%;
    }

    .transaction-filter-wrapper .transactions-balance-point {
        width: 100%;
        margin-right: auto;
        justify-content: left;
    }

    .transaction-filter {
        justify-content: center;
    }
    .transaction-filter-wrapper .transaction-filter-dropdown {
        width: 100%;
    }
    .transaction-date-filter {
        width: 100%;
    }
    .transaction-filter-dropdown-toggle {
        margin-top: 10px;
        width: 100%;
    }

}

.transaction-table {
    width: 100%;
    margin-bottom: 40px;
    border-radius: 0 0 8px 8px !important;
}

.transaction-table thead {
    background: linear-gradient(180deg, #fff, #f7f8f5);
}

.transaction-table thead th:first-child {
    text-align: left;
}

.transaction-table thead th {
    border: 1px solid #e8e6d0;
    padding: 14px 12px;
    font-weight: 700;
    font-size: 11px;
}

.transaction-table thead th:last-child {
    text-align: right;
}

.transaction-table tbody td:first-child {
    text-align: left;
}

.transaction-table tbody td {
    background: linear-gradient(180deg, #fff, #f7f8f5);
    border: 1px solid #e8e6d0;
    padding: 14px 12px;
}

.transaction-table tbody td:nth-child(2) {
    text-align: center;
}

.transaction-table tbody td:last-child {
    text-align: right;
}

.transaction-table tbody td .total-amount {
    color: green;
    font-size: 12px;
    font-weight: 700;
    text-align: center;
}

.transaction-table tbody td .total-amount.success {
    color: green;
    font-size: 12px;
    font-weight: 700;
    text-align: center;
}

.transaction-table tbody td .total-amount.red {
    color: #e04c4c;
    font-size: 12px;
    font-weight: 700;
    text-align: center;
}




.profile-title {
    font-size: 22px !important;
    font-weight: 700 !important;
    margin-bottom: 20px;
}

.profile-wrapper {
    margin-top: 20px;
    border-radius: 12px;
    border: 1px solid #d4d6ce;
    background: #fff;
}

.user-row {
    border-radius: 12px 12px 0 0;
    border-bottom: 1px solid #d4d6ce;
    background: #f7f8f5;
    display: flex;
    padding: 24px 8px 24px 12px;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    flex-direction: column;
}

.info-row {
    display: flex;
    justify-content: space-between;
    padding: 16px 8px 16px 12px;
    cursor: pointer;
    border-bottom: 1px solid #d4d6ce;
}

.address-row {
    display: flex;
    justify-content: space-between;
    padding: 16px 8px 16px 12px;
    cursor: pointer;
}

.info-row-icon {
    display: flex;

}

.not-confirmed-text {
    margin: auto;
    font-size: 11px;
    display: flex;
    font-weight: 500;
    border-radius: 4px;
    background: #fff4f5;
    color: #e04c4c !important;
    padding: 4px;

}

.confirmed-text {
    margin: auto;
    font-size: 11px;
    display: flex;
    border-radius: 4px;
    padding: 4px;
    background: #eafaef !important;
    color: #336c68 !important;
    font-weight: 600;
}


.info-row-icon svg {
    width: 24px;
    height: 24px;
    margin: auto;
}

.not-confirmed-text svg, .confirmed-text svg {
    width: 10px !important;
    height: 10px !important;
}

.profile-city {
    min-width: 140px;
}

.info-long-text {
    text-overflow: ellipsis;
    overflow: hidden;
    width: 700px;
    white-space: nowrap;
}


.settings-wrapper {
    border-radius: 8px;
    background: #f7f8f5;
    padding: 24px 16px 16px;
}

.setting-row {
    border-top: 1px solid var(--border-color);
    display: flex;
    justify-content: space-between;
    padding: 16px 0;
}


.toggle-switch {
    cursor: pointer;
    display: inline-block
}

.toggle-switch-area {
    display: inline-block;
    background: #fff;
    border: 1px solid var(--border-color);
    border-radius: 16px;
    width: 48px;
    height: 24px;
    position: relative;
    vertical-align: middle;
    transition: background .25s
}

.toggle-switch-area:after, .toggle-switch-area:before {
    content: ""
}

.toggle-switch-area:before {
    display: block;
    background-color: #929192;
    border-radius: 50%;
    width: 18px;
    height: 18px;
    position: absolute;
    top: 2px;
    left: 2px;
    transition: left .25s
}

.toggle-switch:hover .toggle-switch-area:before {
    background: #929192
}

.toggle-switch-input:checked + .toggle-switch-area {
    background: var(--border-green-color);
    border: 1px solid var(--primary-color)
}

.toggle-switch-input:checked + .toggle-switch-area:before {
    left: 26px;
    background: #fff
}

.toggle-switch-input {
    position: absolute;
    visibility: hidden
}

.toggle-switch-label {
    margin-left: 5px;
    position: relative;
    top: 2px
}


.deleteBankIcon {
    display: flex;
    justify-content: center;
}

.deleteBankIcon svg {
    display: flex;
    width: 64px;
    height: 64px;
    padding: 16px 15.5px 16px 16.5px;
    justify-content: center;
    align-items: center;
    border-radius: 32px;
    background: #fff4f4;
}


.mobile-ticket-wrapper {
    margin-bottom: 10px;
    border-radius: 10px;
    padding: 0 10px;
    border: 1px solid #d4d6ce;
}


.mobile-ticket-detail-row {
    display: flex;
    justify-content: space-between;
    padding: 3px 0;
}

.mobile-ticket-detail-btn {
    display: flex !important;
    width: 100%;
    justify-content: space-between !important;
    background: rgba(0, 71, 66, .102) !important;
    padding: 10px 18px;
    margin-bottom: 10px;
    margin-top: 10px;
    align-items: center;
}


.mobile-ticket-cancel-btn {
    display: flex !important;
    width: 100%;
    justify-content: space-between !important;
    align-items: center;
    font-weight: 600;
    background: var(--error-color) !important;
    padding: 10px 18px;
    margin-bottom: 10px;
    margin-top: 10px;
}


.ticket-replay-btn {
    border-radius: 8px 0 0 8px !important;
    height: 32px !important;;
    width: 50px !important;;
    border-right: 0 !important;
}

.cancel-coupon-btn {
    border-radius: 0 8px 8px 0 !important;
    height: 32px !important;
    width: 50px !important;;
}
