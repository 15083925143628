.home-grid {

    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(512px, 1fr));
    grid-gap: 27px 16px;
    gap: 27px 16px;
    padding-bottom: 120px;
    padding-top: 15px;
}

@media screen and (max-width: 768px) {
    .home-grid {
        grid-template-columns:1fr;
        gap: 8px;
        padding-top: 0;
        margin-top: 10px;
    }
}

.home-widget {
    border-radius: 14px;
    border: 1px solid #e8e6d0;
    background: #f7f8f5;
    display: flex;
    flex-direction: column;
}

.home-widget > .header {
    background-color: var(--primary-color);
    padding: 15px 12px;
    border-radius: 14px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0;
    min-height: 50px;
    width: 100%;
}

@media screen and (max-width: 768px) {
    .home-widget > .header {
        border-radius: 14px;
        /*border: 1px solid #e8e6d0;*/
        background: linear-gradient(180deg, #fff, #f7f8f5) !important;
    }

    .home-widget > .header > div:first-child span {
        color: var(--primary-color) !important;
    }

    .home-widget > .header.isOpen {
        border-radius: 10px;
        background: var(--primary-color) !important;

    }

    .home-widget > .header.isOpen > div:first-child span {

        color: #FFFFFF !important;
    }
}


.home-widget > .header.isOpen {
    border-radius: 14px 14px 0 0;
}

.rotate-svg {
    transition: all .2s ease;
    transform: rotate(180deg);
}


.home-widget > .header > div > span {
    font-weight: bold;
}

.pill-tab {
    background: var(--primary-light-color);

    font-size: var(--font-size-xxs);
    font-weight: var(--font-weight-bold);

    color: var(--background-color);
    width: 100%;
    cursor: pointer;
    background: var(--primary-light-color);

    padding: 7px 16px;

}

@media screen and (max-width: 768px) {
    .pill-tab {
        padding: 10px 30px;
    }
}

.pill-tab.right {

    border-radius: 0 8px 8px 0;
}

.pill-tab.left {
    border-radius: 8px 0 0 8px;
}

.pill-tab.all {
    border-radius: 8px;
}

.pill-tab.active {
    background: var(--background-color);
    color: var(--primary-color);
}

.widget-table tr td {

    font-size: var(--font-size-xs);
    color: var(--text-color);
    text-align: left;
    font-weight: var(--font-weight-bold);
}

.daily-result-table tr {
    display: grid;
    grid-template-columns: 20px 1fr 100px 80px;
}

.daily-result-table tr:first-child {
    border-bottom: 1px solid #e8e6d0;
}

.daily-result-table tr:nth-child(odd) {
    background-color: #f7f8f5;
}

.daily-result-table tr th:first-child {
    border-right: 1px solid #ded35b;
    padding: 8px 0;
}

.daily-result-table tr th:not(:last-child) {
    border-right: 1px solid #e8e6d0;
}
.daily-result-table tr th {
    background-color: var(--secondary-color);
    text-align: left;
}

.daily-result-table tr th:not(:last-child) {
    border-right: 1px solid #e8e6d0;
}

.daily-result-table tr th:not(:first-child) {
    padding: 8px 12px;
}
.daily-result-table tr:nth-child(2n) {
    background-color: #fff;
}

.daily-result-table tr td:first-child {
    padding: 12px 0;
}

.daily-result-table tr td:not(:last-child) {
    border-right: 1px solid #e8e6d0;
}
.daily-result-table tr td {
    overflow: hidden;
    text-overflow: ellipsis;
    border-bottom: 1px solid #e8e6d0;
    /*text-align: center;*/
}

.daily-result-table tr td:not(:first-child) {
    padding: 12px 14px;

}

/*.changed-jockey-table tr {*/
/*    display: grid;*/
/*    grid-template-columns: 54px 100px 1fr;*/
/*}*/

.changed-jockey-table table {
    width: 100%
}

.changed-jockey-table tr {
    display: grid;
    text-align: left;
    grid-template-columns: 54px 100px 1fr
}

.changed-jockey-table tr:nth-child(odd) {
    background-color: #f7f8f5
}

.changed-jockey-table tr:nth-child(2n) {
    background-color: #fff
}

.changed-jockey-table tr:last-child {
    border-bottom-right-radius: 12px;
    border-bottom-left-radius: 12px
}

.changed-jockey-table tr:last-child td:last-child {
    border-bottom-right-radius: 12px
}

.changed-jockey-table tr:last-child td:first-child {
    border-bottom-left-radius: 12px
}

.changed-jockey-table tr th {
    background-color: var(--secondary-color);
    padding: 10px 12px
}

.changed-jockey-table tr th:not(:last-child) {
    border-right: 1px solid #ded35b
}

.changed-jockey-table tr td {
    padding: 10.5px 12px;
    border-bottom: 1px solid #e8e6d0
}

.changed-jockey-table tr td:not(:last-child) {
    border-right: 1px solid #e8e6d0;
    text-align: center
}

.agf-table tr {
    display: grid;
    grid-template-columns: 30px 1fr 1fr 1fr;
}

.not-running-horse-table tr {
    display: grid;
    text-align: left;
    grid-template-columns: 54px 50px 1fr;
}

.not-running-horse-table tr:nth-child(odd) {
    background-color: #f7f8f5;
}

.not-running-horse-table tr th:first-child, .not-running-horse-table tr th:not(:last-child) {
    border-right: 1px solid #ded35b;
}

.not-running-horse-table tr th {
    background-color: var(--secondary-color);
    padding: 10px 12px;
}

.not-running-horse-table tr th:nth-child(2) {
    text-align: center;
}

.not-running-horse-table tr:nth-child(2n) {
    background-color: #fff;
}

.widget-table tr td:not(:first-child) {
    padding: 12px 14px;
}

.not-running-horse-table tr td:not(:last-child) {
    border-right: 1px solid #e8e6d0;
}

.not-running-horse-table tr td {
    padding: 14px 12px;
    border-bottom: 1px solid #e8e6d0;
}

.not-running-horse-table tr td:nth-child(2) {
    text-align: center;
}

.daily-result-table tr td:first-child {

    background-color: rgb(233, 217, 7)

}

.widget-table > thead > tr:nth-child(odd) {
    background-color: #f7f8f5;
}

.widget-table > thead > tr > th {
    background-color: var(--secondary-color);
    text-align: left;
    color: var(--text-color);
    font-weight: var(--font-weight-bold);
    font-size: var(--font-size-xxs);
    display: flex;
    align-items: center;
}

.widget-table > thead > tr > th:not(:last-child) {
    border-right: 1px solid #e8e6d0;
    padding: 8px 12px;
}

.daily-race-container {
    padding: 12px 8px;
    background-color: var(--input-background-color);
    border-radius: 8px;
    margin-bottom: 8px;
}

.race-start {
    background-color: var(--text-color);
    padding: 2px 6px;
    border-radius: 30px;
    display: inline-flex;
    align-items: center;
    margin-right: 4px;
    margin-left: 6px;
}

.races-bets {
    overflow: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.bet-wrapper {
    background-color: var(--primary-color);
    width: 43px !important;
    height: 26px;
    min-width: 43px;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 4px;
}

.caption {
    padding: 8px 12px;
    background-color: var(--secondary-color);
    text-align: center;
    color: var(--text-color);
    font-weight: var(--font-weight-bold);
    font-size: var(--font-size-xxs);
    display: flex;
    align-items: center;
    border-right: 1px solid #e8e6d0;
    justify-content: center;
    height: 50px;

}


.possibles-table .runs .line.high {
    background: #004742 !important;
    color: #FFFFFF !important;
}

.possibles-table .runs .line.high span {

    color: #FFFFFF !important;
}

.possibles-table .runs .line.low {
    background: #66918e !important;
    color: #FFFFFF !important;
}

.possibles-table .runs .line.low span {

    color: #FFFFFF !important;
}


@media screen and (max-width: 768px) {
    .widget-table tr td:not(:first-child) {
        padding: 12px 5px;
        white-space: nowrap;
    }
}
